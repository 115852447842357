<div class="esg-validation-PlanAction">
  <div class="esg-entryContentBlock-content">
    <!-- Action plan graph -->
    <div class="esg-entryContentBlock-content esg-actionPlanGraphBlock">
      <h2>{{ "action_plan.title" | translate }}</h2>
      <div class="row">
        <div class="medium-6 columns esg-actionPlanGraphBlock-left">
          <h3>{{ "action_plan.principles" | translate }}</h3>
          <div
            class="esg-actionPlanGraphBlock-item"
            *ngFor="let r of actionPlanService.results"
          >
            <span class="esg-list">{{ r.short_title | translate }}</span>
            <span
              class="esg-box"
              [ngClass]="{
                'esg-blue': r.status === 2,
                'esg-red': r.status === 1
              }"
            ></span>
            <span
              class="esg-box"
              [ngClass]="{
                'esg-green': r.status === 3,
                'esg-red': r.status === 1
              }"
            ></span>
          </div>
        </div>
        <div class="medium-6 columns esg-actionPlanGraphBlock-right">
          <h3>{{ "action_plan.measures" | translate }}</h3>
          <div class="esg-actionPlanGraphBlock-right-measures">
            <div
              class="esg-actionPlanGraphBlock-right-measures-scroll"
              *ngIf="actionPlanService.measures"
            >
              <div
                *ngFor="let measure of actionPlanService.measures"
                class="esg-actionPlanGraphBlock-item"
              >
                <span
                  class="esg-box"
                  [ngClass]="{
                    'esg-blue': measure.status === 2,
                    'esg-red': measure.status === 1
                  }"
                ></span>
                <span
                  class="esg-box"
                  [ngClass]="{
                    'esg-green': measure.status === 3,
                    'esg-red': measure.status === 1
                  }"
                ></span>
                <span class="esg-list">{{ measure.name }}</span>
              </div>
            </div>
          </div>
          <h3>{{ "action_plan.risks" | translate }}</h3>
          <div class="esg-actionPlanGraphBlock-item">
            <span
              class="esg-box"
              [ngClass]="{
                'esg-blue':
                  actionPlanService.risks['3.2'] &&
                  actionPlanService.risks['3.2']['status'] === 2,
                'esg-red':
                  actionPlanService.risks['3.2'] &&
                  actionPlanService.risks['3.2']['status'] === 1
              }"
            ></span>
            <span
              class="esg-box"
              [ngClass]="{
                'esg-green':
                  actionPlanService.risks['3.2'] &&
                  actionPlanService.risks['3.2']['status'] === 3,
                'esg-red':
                  actionPlanService.risks['3.2'] &&
                  actionPlanService.risks['3.2']['status'] === 1
              }"
            ></span>
            <span class="esg-list">{{ "action_plan.risk1" | translate }}</span>
          </div>
          <div class="esg-actionPlanGraphBlock-item">
            <span
              class="esg-box"
              [ngClass]="{
                'esg-blue':
                  actionPlanService.risks['3.3'] &&
                  actionPlanService.risks['3.3']['status'] === 2,
                'esg-red':
                  actionPlanService.risks['3.3'] &&
                  actionPlanService.risks['3.3']['status'] === 1
              }"
            ></span>
            <span
              class="esg-box"
              [ngClass]="{
                'esg-green':
                  actionPlanService.risks['3.3'] &&
                  actionPlanService.risks['3.3']['status'] === 3,
                'esg-red':
                  actionPlanService.risks['3.3'] &&
                  actionPlanService.risks['3.3']['status'] === 1
              }"
            ></span>
            <span class="esg-list">{{ "action_plan.risk2" | translate }}</span>
          </div>
          <div class="esg-actionPlanGraphBlock-item">
            <span
              class="esg-box"
              [ngClass]="{
                'esg-blue':
                  actionPlanService.risks['3.4'] &&
                  actionPlanService.risks['3.4']['status'] === 2,
                'esg-red':
                  actionPlanService.risks['3.4'] &&
                  actionPlanService.risks['3.4']['status'] === 1
              }"
            ></span>
            <span
              class="esg-box"
              [ngClass]="{
                'esg-green':
                  actionPlanService.risks['3.4'] &&
                  actionPlanService.risks['3.4']['status'] === 3,
                'esg-red':
                  actionPlanService.risks['3.4'] &&
                  actionPlanService.risks['3.4']['status'] === 1
              }"
            ></span>
            <span class="esg-list">{{ "action_plan.risk3" | translate }}</span>
          </div>
        </div>
        <div class="esg-legend">
          <p style="font-weight: 700; color:#452D7B">
            {{ "action_plan.improvable_measures" | translate }}
          </p>
          <p style="font-weight: 700; color:#4BB78B">
            {{ "action_plan.acceptable_measures" | translate }}
          </p>
        </div>
      </div>
    </div>

    <!-- Principles action plans -->
    <div class="esg-entryContentBlock-content-fundamental">
      <h2>{{ "action_plan.principles" | translate }}</h2>
      <div
        *ngIf="
          !actionPlanService.principlesActionPlanReady;
          else listPrinciplesActionPlanComment
        "
      >
        <div class="esg-entryContentBlock-content-details-blocks">
          <div class="esg-details-blocks-content">
            <div>{{ "action_plan.no_action_plan" | translate }}</div>
          </div>
        </div>
      </div>
      <ng-template #listPrinciplesActionPlanComment>
        <app-action-plan-implementation
          [pia]="pia"
          *ngFor="let data of actionPlanService.results"
          [data]="data"
        ></app-action-plan-implementation>
      </ng-template>
    </div>
    <!-- Measures action plans -->
    <div class="esg-entryContentBlock-content-measures">
      <h2>{{ "action_plan.measures" | translate }}</h2>
      <div
        *ngIf="
          !actionPlanService.measuresActionPlanReady;
          else listMeasuresActionPlanComment
        "
      >
        <div class="esg-entryContentBlock-content-details-blocks">
          <div class="esg-details-blocks-content">
            <div>{{ "action_plan.no_action_plan" | translate }}</div>
          </div>
        </div>
      </div>
      <ng-template #listMeasuresActionPlanComment>
        <app-action-plan-implementation
          [pia]="pia"
          *ngFor="let data of actionPlanService.measures"
          [data]="data"
        ></app-action-plan-implementation>
      </ng-template>
    </div>
    <!-- Risks action plans -->
    <div class="esg-entryContentBlock-content-risks">
      <h2>{{ "action_plan.risks" | translate }}</h2>
      <div
        *ngIf="
          !actionPlanService.risksActionPlan32Ready &&
            !actionPlanService.risksActionPlan33Ready &&
            !actionPlanService.risksActionPlan34Ready;
          else listRisksActionPlanComment
        "
      >
        <div class="esg-entryContentBlock-content-details-blocks">
          <div class="esg-details-blocks-content">
            <div>{{ "action_plan.no_action_plan" | translate }}</div>
          </div>
        </div>
      </div>
      <ng-template #listRisksActionPlanComment>
        <div *ngIf="actionPlanService.risks['3.2']">
          <app-action-plan-implementation
            [pia]="pia"
            [data]="actionPlanService.risks['3.2']"
          ></app-action-plan-implementation>
        </div>
        <div *ngIf="actionPlanService.risks['3.3']">
          <app-action-plan-implementation
            [pia]="pia"
            [data]="actionPlanService.risks['3.3']"
          ></app-action-plan-implementation>
        </div>
        <div *ngIf="actionPlanService.risks['3.4']">
          <app-action-plan-implementation
            [pia]="pia"
            [data]="actionPlanService.risks['3.4']"
          ></app-action-plan-implementation>
        </div>
      </ng-template>
    </div>
  </div>
</div>
