<div class="esg-sectionsBlock">
  <div class="esg-sectionBlock-header">
    <h1 class="truncate" title="{{ pia.name }}">{{ pia.name }}</h1>
    <a
      class="esg-icons esg-icon-close-big"
      routerLink="/entries"
      title="{{ 'esg.sections.homepage_link' | translate }}"
    ></a>
  </div>

  <div class="esg-sectionBlock-structure" *ngIf="pia.structure_id">
    {{ "esg.sections.based_on_structure_name" | translate }}
    {{ pia.structure_name }}
  </div>

  <div class="esg-sectionBlock-category" *ngIf="pia.category">
    {{ "homepage.cards.category" | translate }} "{{ pia.category }}"
  </div>

  <div class="esg-sectionBlock-category" *ngIf="editMode !== 'local'">
    {{ "users.role.label" | translate }}: [<ng-container
      *ngFor="let item of editMode; let i = index"
      >{{ "users.role." + item | translate
      }}<span *ngIf="i < editMode.length - 1">, </span></ng-container
    >]
  </div>

  <div class="esg-sectionBlock-body" *ngIf="!loading; else statusSettingElse">
    <div
      *ngFor="let dataSection of data?.sections"
      [ngClass]="dataSection.title.split('.').join('-')"
    >
      <div class="esg-sectionBlock-title">
        {{ dataSection.title | translate }}
        <hr />
      </div>
      <ul
        class="esg-sectionBlock-list"
        *ngIf="pia && section && item && sidStatusService.itemStatus"
      >
        <ng-container *ngFor="let dataItem of dataSection?.items">
          <li *ngIf="!dataItem.hide">
            <a
              [routerLink]="[
                '/esg',
                pia.id,
                'section',
                dataSection.id,
                'item',
                dataItem.id
              ]"
              [ngClass]="{
                active: section.id == dataSection.id && item.id == dataItem.id
              }"
              class="truncate"
            >
              {{ dataItem.title | translate }}</a
            >
            <i
              class="fa"
              *ngIf="
                sidStatusService.sidStatusIcon[
                  sidStatusService.itemStatus[
                    dataSection.id + '.' + dataItem.id
                  ]
                ];
                else defaultIcon
              "
              [ngClass]="
                sidStatusService.sidStatusIcon[
                  sidStatusService.itemStatus[
                    dataSection.id + '.' + dataItem.id
                  ]
                ]
              "
              aria-hidden="true"
            ></i>
            <ng-template #defaultIcon> </ng-template>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
  <ng-template #statusSettingElse>
    <div
      class="esg-sectionBlock-body"
      style="position:relative; height: 150px;"
    >
      <app-loading-overlay
        [visibility]="true"
        [childMode]="true"
      ></app-loading-overlay>
    </div>
  </ng-template>
</div>

<div class="esg-validateESGBlock">
  <ng-container
    *ngIf="
      ((editMode.includes('validator') || editMode === 'local') &&
        sidStatusService.enablePiaValidation &&
        sidStatusService.enableDpoValidation) ||
        (sidStatusService.piaIsRefused && sidStatusService.enableDpoValidation);
      else defaultButton
    "
  >
    <a
      [routerLink]="['/esg', pia.id, 'section', '4', 'item', '4']"
      class="btn btn-green btn-active"
      *ngIf="sidStatusService.enablePiaValidation"
      >{{ "esg.sections.validate_esg" | translate }}</a
    >
    <a
      [routerLink]="['/esg', pia.id, 'section', '4', 'item', '5']"
      class="btn btn-red btn-active"
      *ngIf="sidStatusService.piaIsRefused"
      >{{ "esg.sections.refuse_esg" | translate }}</a
    >
  </ng-container>
  <ng-template #defaultButton>
    <a class="btn btn-default-esg-outline btn-inactive">{{
      "esg.sections.validate_esg" | translate
    }}</a>
  </ng-template>
</div>
