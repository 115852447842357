<main [ngClass]="appDataService.contrastMode ? 'esg-contrast' : ''">
  <app-header
    *ngIf="isMobileDevice() == 'screen' && goMobile === true && router.url !== '/' && router.url !== '/url'"
  ></app-header>
  <router-outlet
    *ngIf="isMobileDevice() == 'screen' && goMobile === true"
  ></router-outlet>
  <div
    *ngIf="isMobileDevice() == 'mobile' && goMobile === false"
    class="no-responsive"
  >
    <img src="assets/images/esg_logo2.svg" alt="ESG Logo" />

    <div class="column">
      <img src="assets/images/responsive.svg" alt="ESG Logo" />
      <span class="span1">{{ "no_responsive_page.text_1" | translate }}</span>
      <span class="span2">{{ "no_responsive_page.text_2" | translate }}</span>
      <a (click)="goMobile = true">{{
        "no_responsive_page.text_3" | translate
      }}</a>
    </div>
  </div>
</main>
