<div class="esg-exportBlock hide-for-print">
  <div class="esg-exportBlock-title" *ngIf="!editMode">
    <i class="fa fa-download"></i>
    {{ "summary.actions.print_and_download" | translate }}
  </div>
  <!-- Print -->
  <div class="esg-exportBlock-print">
    <button
      style="height: 34px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;"
      onclick="print()"
    >
      {{ "summary.actions.print" | translate }}
    </button>
  </div>

  <!-- Individual exports -->
  <div
    style="border-radius: 10px !important;"
    class="esg-exportBlock-individual"
  >
    <div class="esg-exportBlock-individual-description">
      <div>{{ "summary.actions.individual_download" | translate }}</div>
    </div>
    <div class="esg-exportBlock-individual-links">
      <div>{{ "summary.actions.individual_download_select" | translate }}</div>
      <ul>
        <li>
          <input
            type="checkbox"
            (change)="onSelectDownload('pdf', $event.target.checked)"
            name="pdf"
            value="pdf"
            id="pdf"
          />
          <label for="pdf">
            <span>{{
              "summary.actions.individual_download_pdf" | translate
            }}</span>
          </label>
        </li>
        <li>
          <input
            type="checkbox"
            (change)="onSelectDownload('doc', $event.target.checked)"
            name="doc"
            value="doc"
            id="doc"
          />
          <label for="doc">
            <span>{{
              "summary.actions.individual_download_doc" | translate
            }}</span>
          </label>
        </li>
        <li>
          <input
            type="checkbox"
            (change)="onSelectDownload('images', $event.target.checked)"
            name="images"
            value="images"
            id="images"
          />
          <label for="images">
            <span>{{
              "summary.actions.individual_download_images" | translate
            }}</span>
          </label>
        </li>
        <li>
          <input
            type="checkbox"
            (change)="onSelectDownload('csv', $event.target.checked)"
            name="csv"
            value="csv"
            id="csv"
          />
          <label for="csv">
            <span>{{ "summary.action_plan.title" | translate }}</span>
          </label>
        </li>
        <li>
          <input
            type="checkbox"
            (change)="onSelectDownload('json', $event.target.checked)"
            name="json"
            value="json"
            id="json"
          />
          <label for="json">
            <span>{{
              "summary.actions.individual_download_json" | translate
            }}</span>
          </label>
        </li>
      </ul>
      <button
        (click)="launchDownload()"
        class="btn-green"
        [disabled]="exportSelected.length < 1"
      >
        {{ "summary.actions.individual_to_download" | translate }}
      </button>
    </div>
  </div>
</div>
