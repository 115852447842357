import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { AppDataService } from './services/app-data.service';
import { KnowledgeBaseService } from './services/knowledge-base.service';
import { LanguagesService } from './services/languages.service';
import { Renderer2 } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public goMobile = false;
  constructor(private http: HttpClient,
    public router: Router,
    private renderer: Renderer2,
    private knowledgeBaseService: KnowledgeBaseService,
    private languagesService: LanguagesService,
    public appDataService: AppDataService) {

    this.knowledgeBaseService.loadData(this.http);

    // const increaseContrast = this.appDataService.contrastMode;

    // if (increaseContrast) {
    //   this.renderer.addClass(document.body, 'esg-contrast');
    // } else {
    //   this.renderer.removeClass(document.body, 'esg-contrast');
    // }

    // Languages initialization
    this.languagesService.initLanguages();
    this.languagesService.getOrSetCurrentLanguage();

  }

  isMobileDevice(): string {
    if (this.goMobile === false) {
      if (window.innerWidth <= 599) {
        this.goMobile = false
        return "mobile"
      } else {
        this.goMobile = true
        return "screen"
      }
    } else {
      this.goMobile = true
      return "screen"
    }

  }
}
