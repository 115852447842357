<!-- <section
  style="border-radius: 10px; position:relative"
  class="container esg-authenticationBlock"
>
  <div class="row">
    <div class="small-12 medium-8 columns esg-authenticationBlock-logo">
      <div>
        <img src="assets/images/esg-auth-logo.png" alt="Logo ESG" />
        <div>
          <h1 [innerHTML]="'authentication.title' | translate"></h1>
          <div>{{ "header.logo_subtitle" | translate }}</div>
        </div>
      </div>
      <div>
        <h2 [innerHTML]="'authentication.subtitle1' | translate"></h2>
        <p>{{ "authentication.subtitle2" | translate }}</p>
      </div>
    </div>
    <div class="small-12 medium-4 columns">
      <div style="border-radius: 10px;" class="esg-authenticationBlock-auth">
        <div *ngIf="authService.state; else elseBlock">
          <div [ngSwitch]="stepForm">
            Initial block / Sign in
            <ng-container *ngSwitchCase="'logIn'">
              <app-sign-in
                [fromSignUp]="fromValidation"
                (validated)="onSubmited()"
                (canceled)="changeDisplay('checkUuid')"
                (forget)="changeDisplay('forgetPassword')"
              ></app-sign-in>
            </ng-container>

            New account - Activation code
            <ng-container *ngSwitchCase="'checkUuid'">
              <app-uuid
                (canceled)="changeDisplay('logIn')"
                (validated)="changeDisplay('signUp'); accountData = $event"
              ></app-uuid>
            </ng-container>

            New account - Define password
            <ng-container *ngSwitchCase="'signUp'">
              <app-password
                (validated)="changeDisplay('logIn'); fromValidation = $event"
                (canceled)="changeDisplay('checkUuid')"
                [accountData]="accountData"
              ></app-password>
            </ng-container>

            Account recovery - Email
            <ng-container *ngSwitchCase="'forgetPassword'">
              <app-forget-password
                (canceled)="changeDisplay('logIn')"
                (validated)="changeDisplay('resetPassword')"
              ></app-forget-password>
            </ng-container>

            Account recovery - Reset code
            <ng-container *ngSwitchCase="'resetPassword'">
              <app-uuid
                [reset]="true"
                (canceled)="changeDisplay('forgetPassword')"
                (validated)="changeDisplay('newPassword'); accountData = $event"
              ></app-uuid>
            </ng-container>

            Account recovery - New password
            <ng-container *ngSwitchCase="'newPassword'">
              <app-password
                [reset]="true"
                (canceled)="changeDisplay('resetPassword')"
                (validated)="changeDisplay('logIn'); fromValidation = $event"
                [accountData]="accountData"
              ></app-password>
            </ng-container>
          </div>
        </div>
        <ng-template #elseBlock>
          <div
            style="border-radius: 10px;padding: 24px;"
            class="esg-authenticationBlock-enter"
          >
            <div>{{ "authentication.start_app" | translate }}</div>
            <p
              class="esg-authenticationBlock-warning"
              [innerHTML]="'authentication.description' | translate"
            ></p>
            <a routerLink="/entries" class="btn btn-white-esg-outline">{{
              "authentication.start" | translate
            }}</a>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</section>
<a href="https://bluecurseur.com/" target="_blank">
  <img
    class="esg-logo-bluecurseur"
    src="assets/images/bluecurseur.svg"
    alt="ESG Logo"
  />
</a> -->


<section style="display: grid;grid-template-columns: repeat(2, minmax(0, 1fr));height: 100vh;">

  <div
    style="position:relative;background-image: url('assets/images/esg-bg-auth.png');background-repeat: no-repeat;background-size: cover;">
    <img style="position: absolute;left:43px;top:43px;z-index:9999" src="assets/images/esg-logo-text.svg"
      alt="Logo ESG" />
    <p style="
    color: #FFFFFF;
    position: absolute;
    bottom: 21px;
    font-family: Inter;
    font-style: italic;
    line-height: 24px;
    font-weight: 400;
    font-size: 16.44px;
    text-align: justify;
    width: 90%;
    left: 23px;
    ">{{ "authentication.side_text" |
      translate }}</p>
  </div>
  <div style="background-color: white;position:relative">
    <div *ngIf="authService.state; else elseBlock">
      <div [ngSwitch]="stepForm">
        <!-- Initial block / Sign in -->
        <ng-container *ngSwitchCase="'logIn'">
          <app-sign-in [fromSignUp]="fromValidation" (validated)="onSubmited()" (canceled)="changeDisplay('checkUuid')"
            (forget)="changeDisplay('forgetPassword')"></app-sign-in>
        </ng-container>

        <!-- New account - Activation code -->
        <ng-container *ngSwitchCase="'checkUuid'">
          <app-uuid (canceled)="changeDisplay('logIn')"
            (validated)="changeDisplay('signUp'); accountData = $event"></app-uuid>
        </ng-container>

        <!--New account - Define password-->
        <ng-container *ngSwitchCase="'signUp'">
          <app-password (validated)="changeDisplay('logIn'); fromValidation = $event"
            (canceled)="changeDisplay('checkUuid')" [accountData]="accountData"></app-password>
        </ng-container>

        <!-- Account recovery - Email -->
        <ng-container *ngSwitchCase="'forgetPassword'">
          <app-forget-password (canceled)="changeDisplay('logIn')"
            (validated)="changeDisplay('resetPassword')"></app-forget-password>
        </ng-container>

        <!--Account recovery - Reset code-->
        <ng-container *ngSwitchCase="'resetPassword'">
          <app-uuid [reset]="true" (canceled)="changeDisplay('forgetPassword')"
            (validated)="changeDisplay('newPassword'); accountData = $event"></app-uuid>
        </ng-container>

        <!--Account recovery - New password-->
        <ng-container *ngSwitchCase="'newPassword'">
          <app-password [reset]="true" (canceled)="changeDisplay('resetPassword')"
            (validated)="changeDisplay('logIn'); fromValidation = $event" [accountData]="accountData"></app-password>
        </ng-container>
      </div>
    </div>
    <ng-template #elseBlock>
      <!-- first design -->
      <div style="display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    height: 100vh;">
        <img style="width:33px;margin-bottom:20px" src="assets/images/rocket-icon.svg" alt="Rocket ESG" />
        <span style="font-size: 24px; font-weight:600;text-align:center;margin-bottom:7px">{{ "authentication.start_app"
          | translate }}
        </span>
        <span style="font-size: 13px; font-weight:400;text-align:center;color:#71717A"
          [innerHTML]="'authentication.description' | translate">
        </span>

        <div style="display: flex;gap:10px;margin-top:20px">
          <a routerLink="/entries" style="text-decoration: none;" class="button-auth-primary">{{ "authentication.start"
            |
            translate }}</a>
          <a routerLink="/url" class="button-auth-outline"
            style="display: flex; align-items:center;gap:5px;text-decoration: none;"><img style="width:14px;"
              src="assets/images/setting.svg" alt="Logo ESG" />{{ "header.tools.url_setting" |
            translate }}</a>
        </div>
      </div>
    </ng-template>

    <ul style="margin-bottom: 15px;position:absolute;right:15px;top:15px" class="esg-navigationBlock-list">
      <ng-container>
        <li class="esg-drop-li esg-navigationBlock-withsub esg-navigationBlock-dropdown esg-navigationBlock-help">
          <!-- <a href="javascript:;"> -->
          <!-- {{ "header.help.title" | translate }} -->
          <!-- <i class="fa-solid fa-question"></i> -->
          <!-- <i class="fa-solid fa-circle-info"></i> -->
          <!-- <span class="esg-icons esg-icon-scroll" aria-hidden="true"></span> -->
          <!-- <i style="font-size: 10px;" class="fa-solid fa-chevron-down"></i> -->
          <!-- </a> -->
          <ul style="width: 21rem !important; margin-top: 10px !important;">
            <li *ngIf="
                (languagesService.selectedLanguage === 'fr' &&
                  !authService.state &&
                  router.url !== '/') ||
                authService.currentUserValue !== null
              ">
              <a (click)="goToExample()">
                {{ "header.help.esg_example_link" | translate }}
              </a>
            </li>
            <li>
              <a routerLink="/help">{{ "header.help.manual" | translate }}</a>
            </li>
            <li *ngIf="
                (!authService.state && router.url.includes('/esg')) ||
                authService.currentUserValue !== null
              ">
              <a name="esg-onboarding" id="esg-onboarding" (click)="restartOnboarding()">
                {{ "header.tools.onboarding" | translate }}
              </a>
            </li>
            <!-- <li>
              <a target="_blank"
                href="https://github.com/LINCnil/pia/blob/master/CONTRIBUTING.md#exprimer-un-besoin-ou-probl%C3%A8me">
                {{ "header.help.issue_reporting" | translate }}
              </a>
            </li> -->
          </ul>
        </li>
      </ng-container>
      <!-- esg-navigationBlock-languages -->
      <li class="esg-drop-li esg-navigationBlock-withsub esg-navigationBlock-dropdown ">
        <a href="javascript:;">
          <i class="fa-solid fa-language" aria-hidden="true"></i>
        </a>
        <ul style="margin: 12px -3px 0px 0px !important;width: 200px;">
          <!-- <li class="officialTranslation">
            {{ "header.languages.official_translation" | translate }}
          </li> -->
          <li [ngClass]="{
              is_active: languagesService.selectedLanguage === 'fr'
            }">
            <a href="javascript:;" (click)="languagesService.updateCurrentLanguage('fr')">
              {{ "header.languages.fr" | translate }}
            </a>
          </li>
          <!-- <li class="cnilTranslations">
            {{ "header.languages.cnil_translations" | translate }}
          </li> -->
          <li class="englishTranslation" [ngClass]="{
              is_active: languagesService.selectedLanguage === 'en'
            }">
            <a href="javascript:;" (click)="languagesService.updateCurrentLanguage('en')">
              <!-- <a href="javascript:;"> -->
              {{ "header.languages.en" | translate }}
            </a>
          </li>
          <!-- <li class="dpaTranslations">
            {{ "header.languages.dpa_translations" | translate }}
          </li> -->
          <!-- <li
            [ngClass]="{
              is_active: languagesService.selectedLanguage === 'de'
            }"
          >
            <a
              href="javascript:;"
              (click)="languagesService.updateCurrentLanguage('de')"
            >
              {{ "header.languages.de" | translate }}
            </a>
          </li>
           <li
            [ngClass]="{
              is_active: languagesService.selectedLanguage === 'it'
            }"
          >
            <a
              href="javascript:;"
              (click)="languagesService.updateCurrentLanguage('it')"
            >
              {{ "header.languages.it" | translate }}
            </a>
          </li>
          <li
            [ngClass]="{
              is_active: languagesService.selectedLanguage === 'hu'
            }"
          >
            <a
              href="javascript:;"
              (click)="languagesService.updateCurrentLanguage('hu')"
            >
              {{ "header.languages.hu" | translate }}
            </a>
          </li>
          <li
            [ngClass]="{
              is_active: languagesService.selectedLanguage === 'no'
            }"
          >
            <a
              href="javascript:;"
              (click)="languagesService.updateCurrentLanguage('no')"
            >
              {{ "header.languages.no" | translate }}
            </a>
          </li>
          <li
            [ngClass]="{
              is_active: languagesService.selectedLanguage === 'pl'
            }"
          >
            <a
              href="javascript:;"
              (click)="languagesService.updateCurrentLanguage('pl')"
            >
              {{ "header.languages.pl" | translate }}
            </a>
          </li>
          <li
            [ngClass]="{
              is_active: languagesService.selectedLanguage === 'fi'
            }"
          >
            <a
              href="javascript:;"
              (click)="languagesService.updateCurrentLanguage('fi')"
            >
              {{ "header.languages.fi" | translate }}
            </a>
          </li>
          <li
            [ngClass]="{
              is_active: languagesService.selectedLanguage === 'lv'
            }"
          >
            <a
              href="javascript:;"
              (click)="languagesService.updateCurrentLanguage('lv')"
            >
              {{ "header.languages.lv" | translate }}
            </a>
          </li>
          <li class="communityTranslations">
            {{ "header.languages.community_translations" | translate }}
          </li>
          <li
            [ngClass]="{
              is_active: languagesService.selectedLanguage === 'cz'
            }"
          >
            <a
              href="javascript:;"
              (click)="languagesService.updateCurrentLanguage('cz')"
            >
              {{ "header.languages.cz" | translate }}
            </a>
          </li>
          <li
            [ngClass]="{
              is_active: languagesService.selectedLanguage === 'dk'
            }"
          >
            <a
              href="javascript:;"
              (click)="languagesService.updateCurrentLanguage('dk')"
            >
              {{ "header.languages.dk" | translate }}
            </a>
          </li>
          <li
            [ngClass]="{
              is_active: languagesService.selectedLanguage === 'et'
            }"
          >
            <a
              href="javascript:;"
              (click)="languagesService.updateCurrentLanguage('et')"
            >
              {{ "header.languages.et" | translate }}
            </a>
          </li>
          <li
            [ngClass]="{
              is_active: languagesService.selectedLanguage === 'es'
            }"
          >
            <a
              href="javascript:;"
              (click)="languagesService.updateCurrentLanguage('es')"
            >
              {{ "header.languages.es" | translate }}
            </a>
          </li>
          <li
            [ngClass]="{
              is_active: languagesService.selectedLanguage === 'el'
            }"
          >
            <a
              href="javascript:;"
              (click)="languagesService.updateCurrentLanguage('el')"
            >
              {{ "header.languages.el" | translate }}
            </a>
          </li>
          <li
            [ngClass]="{
              is_active: languagesService.selectedLanguage === 'hr'
            }"
          >
            <a
              href="javascript:;"
              (click)="languagesService.updateCurrentLanguage('hr')"
            >
              {{ "header.languages.hr" | translate }}
            </a>
          </li>
          <li
            [ngClass]="{
              is_active: languagesService.selectedLanguage === 'lt'
            }"
          >
            <a
              href="javascript:;"
              (click)="languagesService.updateCurrentLanguage('lt')"
            >
              {{ "header.languages.lt" | translate }}
            </a>
          </li> -->
          <li [ngClass]="{
              is_active: languagesService.selectedLanguage === 'nl'
            }">
            <!-- <a href="javascript:;"> -->
            <a href="javascript:;" (click)="languagesService.updateCurrentLanguage('nl')">
              {{ "header.languages.nl" | translate }}
            </a>
          </li>
          <!-- <li
            [ngClass]="{
              is_active: languagesService.selectedLanguage === 'pt'
            }"
          >
            <a
              href="javascript:;"
              (click)="languagesService.updateCurrentLanguage('pt')"
            >
              {{ "header.languages.pt" | translate }}
            </a>
          </li>
          <li
            [ngClass]="{
              is_active: languagesService.selectedLanguage === 'ro'
            }"
          >
            <a
              href="javascript:;"
              (click)="languagesService.updateCurrentLanguage('ro')"
            >
              {{ "header.languages.ro" | translate }}
            </a>
          </li>
          <li
            [ngClass]="{
              is_active: languagesService.selectedLanguage === 'sl'
            }"
          >
            <a
              href="javascript:;"
              (click)="languagesService.updateCurrentLanguage('sl')"
            >
              {{ "header.languages.sl" | translate }}
            </a>
          </li>
          <li
            [ngClass]="{
              is_active: languagesService.selectedLanguage === 'sv'
            }"
          >
            <a
              href="javascript:;"
              (click)="languagesService.updateCurrentLanguage('sv')"
            >
              {{ "header.languages.sv" | translate }}
            </a>
          </li>
          <li
            [ngClass]="{
              is_active: languagesService.selectedLanguage === 'lv'
            }"
          >
            <a
              href="javascript:;"
              (click)="languagesService.updateCurrentLanguage('lv')"
            >
              {{ "header.languages.lv" | translate }}
            </a>
          </li>
          <li
            [ngClass]="{
              is_active: languagesService.selectedLanguage === 'bg'
            }"
          >
            <a
              href="javascript:;"
              (click)="languagesService.updateCurrentLanguage('bg')"
            >
              {{ "header.languages.bg" | translate }}
            </a>
          </li> -->
        </ul>
      </li>
    </ul>

  </div>
</section>


<app-dialog></app-dialog>