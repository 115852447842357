<div class="esg-sectionsBlock">
  <div class="esg-sectionBlock-header">
    <h1 class="truncate" title="{{ structure.name }}">{{ structure.name }}</h1>
    <a
      class="esg-icons esg-icon-close-big"
      [routerLink]="['/entries', 'structure']"
      title="{{ 'esg.sections.homepage_link' | translate }}"
    ></a>
  </div>

  <div class="esg-sectionBlock-body">
    <div *ngFor="let dataSection of data.sections">
      <ng-container *ngIf="[1, 2, 3].includes(dataSection.id)">
        <div class="esg-sectionBlock-title">
          {{ dataSection.title | translate }}
          <hr />
        </div>
        <ul class="esg-sectionBlock-list" *ngIf="structure && section && item">
          <ng-container *ngFor="let dataItem of dataSection?.items">
            <li
              *ngIf="
                !dataItem.hide &&
                !['3.2', '3.3', '3.4', '3.5'].includes(
                  dataSection.id + '.' + dataItem.id
                )
              "
            >
              <a
                [routerLink]="[
                  '/structures',
                  structure.id,
                  'section',
                  dataSection.id,
                  'item',
                  dataItem.id
                ]"
                [ngClass]="{
                  active: section.id == dataSection.id && item.id == dataItem.id
                }"
                class="truncate"
              >
                {{ dataItem.title | translate }}</a
              >
              <i
                class="fa fa-sitemap"
                [ngClass]="{
                  'esg-fa-valid':
                    sidStatusService.structureStatus[
                      dataSection.id + '.' + dataItem.id
                    ] === true
                }"
                aria-hidden="true"
              ></i>
            </li>
          </ng-container>
        </ul>
      </ng-container>
    </div>
  </div>
</div>
