<div
  style="border-radius:10px;"
  class="esg-attachmentsBlock"
  *ngIf="attachments"
>
  <p>{{ "esg.attachments.title" | translate }}</p>

  <ng-container *ngIf="attachments.length > 0; else noAttachments">
    <app-attachment-item
      [editMode]="editMode"
      *ngFor="let attachment of attachments; let i = index"
      [attachment]="attachment"
      (deleted)="onDeleted($event)"
      [isPreview]="isPreview"
      [pia]="pia"
    >
    </app-attachment-item>
  </ng-container>
  <ng-template #noAttachments>
    <p>
      <small>{{ "esg.attachments.no_attachment" | translate }}</small>
    </p>
  </ng-template>

  <form
    *ngIf="!isPreview && attachments"
    enctype="multipart/form-data"
    [formGroup]="attachmentForm"
  >
    <input
      type="file"
      formControlName="attachment_file"
      id="attachment_file"
      (change)="uploadAttachement($event)"
      class="hide"
    />
    <button
      class="esg-attachmentsBlock-addBtn btn"
      *ngIf="
        ['author', 'validator', 'evaluator'].indexOf(editMode[0]) >= 0 ||
        editMode === 'local'
      "
      [disabled]="loading"
      [ngClass]="{ hide: !dispplayAttachmentButton }"
      type="button"
      (click)="addAttachment()"
    >
      <ng-container *ngIf="!loading; else loadingElse">
        <span class="esg-icons esg-icon-add"></span>
        {{ "esg.attachments.add" | translate }}
      </ng-container>
      <ng-template #loadingElse>
        {{ "esg.attachments.loading" | translate }}
      </ng-template>
    </button>
  </form>
</div>
