<div class="esg-validationCartographyBlock">
  <div class="esg-entryContentBlock-content">
    <div id="risksCartographyImg" class="esg-cartographyBlock">
      <div>
        <div class="esg-cartographyBlock-gravity">
          {{ "cartography.seriousness_axe" | translate }}
        </div>
        <div class="esg-cartographyBlock-graph">
          <div class="esg-cartographyBlock-ordinates">
            <div>{{ "cartography.maximal" | translate }}</div>
            <div>{{ "cartography.important" | translate }}</div>
            <div>{{ "cartography.limited" | translate }}</div>
            <div>{{ "cartography.negligible" | translate }}</div>
          </div>
          <canvas id="actionPlanCartography" width="400" height="400"></canvas>
        </div>
        <div class="esg-cartographyBlock-abscisses">
          <div>{{ "cartography.negligible" | translate }}</div>
          <div>{{ "cartography.limited" | translate }}</div>
          <div>{{ "cartography.important" | translate }}</div>
          <div>{{ "cartography.maximal" | translate }}</div>
        </div>
        <ul class="esg-cartographyBlock-legend">
          <li>{{ "cartography.legend1" | translate }}</li>
          <li>{{ "cartography.legend2" | translate }}</li>
          <li>{{ "cartography.legend3" | translate }}</li>
          <li>{{ "cartography.legend4" | translate }}</li>
          <li>{{ "cartography.legend5" | translate }}</li>
        </ul>
        <!--
        -->
        <div class="esg-cartographyBlock-probability">
          {{ "cartography.likelihood_axe" | translate }}
        </div>
        <div class="esg-cartographyBlock-date">
          <time>{{
            pia.created_at
              | date: "shortDate":"":languagesService.selectedLanguage
          }}</time>
        </div>
      </div>
    </div>
  </div>
</div>
