<div class="esg-measureBlock" [attr.data-id]="id">
  <form [formGroup]="measureForm">
    <div class="esg-measureBlock-title">
      <button
        class="esg-icons esg-icon-accordion-up"
        type="button"
        (click)="displayMeasure($event)"
        title="{{ 'misc.display' | translate }}"
      ></button>

      <!-- Measure title -->
      <div
        (click)="measureTitleFocusIn()"
        class="esg-measure-title"
        [ngClass]="{ hide: editTitle }"
        [innerHTML]="measureForm.controls['measureTitle'].value | safeHtml"
      ></div>
      <textarea
        maxlength="120"
        rows="2"
        (keyup)="autoTextareaResize($event)"
        [attr.data-id]="id"
        id="esg-measure-title-{{ id }}"
        formControlName="measureTitle"
        (focusout)="measureTitleFocusOut($event)"
        [ngClass]="{ hide: !editTitle }"
        placeholder="{{ 'measures.placeholder_title' | translate }}"
      ></textarea>
    </div>

    <div class="esg-measureBlock-displayer fadeIn">
      <button
        type="button"
        class="btn esg-measureBlock-delete"
        *ngIf="!structure.is_example"
        (click)="removeMeasure()"
        title="{{ 'measures.remove' | translate }}"
      >
        <i class="fa-solid fa-trash" aria-hidden="true"></i>
      </button>

      <!-- Measure answer -->
      <div class="esg-measureBlock-content">
        <div
          (click)="measureContentFocusIn()"
          class="esg-measureBlock-contentText"
          [innerHTML]="measureForm.controls['measureContent'].value | safeHtml"
          [ngClass]="{
            hide: editor || !measureForm.controls['measureContent'].value
          }"
        ></div>
        <textarea
          [attr.data-id]="id"
          id="esg-measure-content-{{ id }}"
          formControlName="measureContent"
          (focusin)="measureContentFocusIn()"
          [ngClass]="{
            hide:
              !editor &&
              measureForm.controls['measureContent'].value &&
              measureForm.controls['measureContent'].value.length > 0
          }"
          rows="8"
          cols="50"
          placeholder="{{ measure.placeholder | translate }}"
        ></textarea>
      </div>
    </div>
  </form>
</div>
