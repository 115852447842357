<section class="container esg-helpBlock esg-mainContainerBlock">
  <div class="row">
    <div class="esg-breadcrumb small-12 medium-12 columns">
      <strong
        >{{ "header.help.title" | translate }} >
        {{ "header.help.manual" | translate }}</strong
      >
    </div>

    <div class="small-12 medium-3 columns">
      <aside class="esg-leftSidebarBlock esg-help-section">
        <div class="esg-sectionBlock-header">
          <h1>{{ "help.title" | translate }}</h1>
          <a
            class="esg-icons esg-icon-close-big"
            routerlink="/entries"
            title="{{ 'esg.sections.homepage_link' | translate }}"
            href="#/entries"
          ></a>
        </div>
        <div *ngFor="let data of tableOfTitles">
          <div class="esg-sectionBlock-title">
            {{ data[0] }}
            <hr />
          </div>
          <ul class="esg-sectionBlock-list">
            <li
              *ngFor="let subTitle of data[1]"
              [ngClass]="{ active: subTitle === activeElement }"
            >
              <a href="javascript:;" (click)="getAnchor($event, subTitle)">{{
                subTitle
              }}</a>
            </li>
          </ul>
        </div>
      </aside>
    </div>
    <div class="small-12 medium-9 columns">
      <div class="esg-helpBlock-content" [innerHTML]="content"></div>
    </div>
  </div>
</section>
