<div
  class="esg-cardsBlock pia"
  *ngIf="!pia.is_example"
  [ngClass]="{
    'esg-exampleBlock': pia.is_example === 1,
    'esg-archiveBlock': pia.status > 1,
    'esg-doingBlock': pia.status < 2
  }"
  [attr.data-id]="pia.id"
>
  <div class="esg-cardsBlock-header-title" *ngIf="pia.id !== null">
    <span *ngIf="!previousPia || previousPia.status !== pia.status">{{
      piaService.getStatusName(pia.status) | translate
    }}</span>
  </div>
  <div class="esg-cardsBlock-item esg-doingBlock-item">
    <div
      *ngIf="attachments && attachments.length > 0"
      class="esg-cardsBlock-item-attachment"
      aria-hidden="true"
    >
      <span class="esg-icons esg-icon-attachment"></span>
      <ul>
        <ng-container *ngFor="let attachment of attachments">
          <li>{{ attachment.name }}</li>
        </ng-container>
      </ul>
    </div>
    <div
      class="esg-cardsBlock-toolbar"
      *ngIf="
        !authService.state ||
        authService.currentUserValue.access_type.includes('technical') ||
        authService.currentUserValue.access_type.includes('functional')
      "
    >
      <div class="esg-cardsBlock-toolbar-export">
        <a
          *ngIf="pia.status >= 2"
          [routerLink]="['/esg', pia.id, 'section', 1, 'item', 1]"
          class="btn esg-tooltip"
        >
          <i class="fa fa-pencil" aria-hidden="true"></i>
          <span
            title="{{ 'homepage.cards.item.edit_esg' | translate }}"
            class="esg-tooltip-text"
            >{{ "homepage.cards.item.edit_esg" | translate }}</span
          >
        </a>
        <a
          href="javascript:;"
          (click)="onDuplicate(pia.id)"
          class="btn esg-tooltip"
        >
          <i class="fa-solid fa-copy" aria-hidden="true"></i>
          <span
            title="{{ 'homepage.cards.item.tools.duplicate' | translate }}"
            class="esg-tooltip-text"
            >{{ "homepage.cards.item.tools.duplicate" | translate }}</span
          >
        </a>
        <a href="javascript:;" (click)="generateZip()" class="btn esg-tooltip">
          <i class="fa fa-download" aria-hidden="true"></i>
          <span
            title="{{ 'homepage.cards.item.tools.export' | translate }}"
            class="esg-tooltip-text"
            >{{ "homepage.cards.item.tools.export" | translate }}</span
          >
        </a>
        <a
          href="javascript:;"
          (click)="archivePia(pia.id)"
          class="btn esg-tooltip"
        >
          <i class="fa fa-archive" aria-hidden="true"></i>
          <span
            title="{{ 'homepage.cards.item.tools.archive' | translate }}"
            class="esg-tooltip-text"
            >{{ "homepage.cards.item.tools.archive" | translate }}</span
          >
        </a>
      </div>
    </div>

    <form
      class="esg-cardsBlock-item-form"
      *ngIf="piaForm"
      [formGroup]="piaForm"
    >
      <input type="hidden" formControlName="id" />
      <div (click)="piaNameFocusIn()" style="margin-top:20px;">
        <label for="esg-edit-{{ pia.id }}-name">{{
          "homepage.cards.esg_name" | translate
        }}</label>
        <input
          type="text"
          #piaName
          formControlName="name"
          required
          placeholder="{{ 'homepage.cards.esg_name' | translate }}"
          id="esg-edit-{{ pia.id }}-name"
          (focusout)="piaNameFocusOut()"
        />
      </div>
      <div>
        <label for="esg-edit-{{ pia.id }}-author-name">{{
          "homepage.cards.author" | translate
        }}</label>

        <div
          class="tag-input-container"
          *ngIf="authService.state; else elseAuthorBlock"
        >
          <tag-input
            #input
            id="esg-edit-{{ pia.id }}-author-name"
            [disable]="
              authService.currentUserValue &&
              !authService.currentUserValue.access_type.includes('functional')
            "
            identifyBy="id"
            displayBy="display"
            formControlName="authors"
            (onAdd)="onAddUser($event, 'authors')"
            (onRemove)="onRemove($event, 'authors')"
            [theme]="'foundation-theme'"
            (onTextChange)="onTyped($event, pia.id, 'authors')"
            [editable]="false"
            [clearOnBlur]="true"
            [placeholder]="'homepage.cards.placeholder_author' | translate"
            [secondaryPlaceholder]="
              'homepage.cards.placeholder_author' | translate
            "
          >
            <ng-template let-item="item" let-index="index">
              <div
                [ngClass]="{
                  'custom-tag': true,
                  'user-not-found': item.id == null
                }"
              >
                {{ item.display }}
                <delete-icon
                  *ngIf="
                    authService.currentUserValue.access_type.includes(
                      'functional'
                    )
                  "
                  (click)="input.removeItem(item, index)"
                ></delete-icon>
              </div>
            </ng-template>
            <tag-input-dropdown
              [keepOpen]="false"
              identifyBy="id"
              [showDropdownIfEmpty]="true"
              [autocompleteItems]="userList"
            ></tag-input-dropdown>
          </tag-input>
          <button
            type="button"
            class="btn btn-add-user"
            (click)="
              onAddUserWithIcon(
                {
                  display: addBtnForSpecificInput.display,
                  id: addBtnForSpecificInput.display
                },
                'authors'
              )
            "
            *ngIf="
              addBtnForSpecificInput &&
              addBtnForSpecificInput.pia_id === pia.id &&
              addBtnForSpecificInput.field === 'authors'
            "
          >
            +
          </button>
        </div>
        <ng-template #elseAuthorBlock>
          <div (click)="piaAuthorNameFocusIn()">
            <input
              type="text"
              #piaAuthorName
              formControlName="author_name"
              required
              placeholder="{{
                'homepage.cards.placeholder_author' | translate
              }}"
              id="esg-edit-{{ pia.id }}-author-name"
              (focusout)="piaAuthorNameFocusOut()"
            />
          </div>
        </ng-template>
      </div>
      <div>
        <label for="esg-edit-{{ pia.id }}-evaluator-name">{{
          "homepage.cards.evaluation" | translate
        }}</label>
        <div
          class="tag-input-container"
          *ngIf="authService.state; else elseEvaluatorBlock"
        >
          <tag-input
            #input
            id="esg-edit-{{ pia.id }}-evaluator-name"
            [disable]="
              authService.currentUserValue &&
              !authService.currentUserValue.access_type.includes('functional')
            "
            identifyBy="id"
            formControlName="evaluators"
            (onTextChange)="onTyped($event, pia.id, 'evaluators')"
            (onAdd)="onAddUser($event, 'evaluators')"
            (onRemove)="onRemove($event, 'evaluators')"
            [theme]="'foundation-theme'"
            [editable]="false"
            [clearOnBlur]="true"
            [placeholder]="'homepage.cards.placeholder_evaluation' | translate"
            [secondaryPlaceholder]="
              'homepage.cards.placeholder_evaluation' | translate
            "
          >
            <ng-template let-item="item" let-index="index">
              <div
                [ngClass]="{
                  'custom-tag': true,
                  'user-not-found': item.id == null
                }"
              >
                {{ item.display }}
                <delete-icon
                  *ngIf="
                    authService.currentUserValue.access_type.includes(
                      'functional'
                    )
                  "
                  (click)="input.removeItem(item, index)"
                ></delete-icon>
              </div>
            </ng-template>
            <tag-input-dropdown
              [keepOpen]="false"
              identifyBy="id"
              [showDropdownIfEmpty]="true"
              [autocompleteItems]="userList"
            ></tag-input-dropdown>
          </tag-input>
          <button
            type="button"
            class="btn btn-add-user"
            (click)="
              onAddUserWithIcon(
                {
                  display: addBtnForSpecificInput.display,
                  id: addBtnForSpecificInput.display
                },
                'evaluators'
              )
            "
            *ngIf="
              addBtnForSpecificInput &&
              addBtnForSpecificInput.pia_id === pia.id &&
              addBtnForSpecificInput.field === 'evaluators'
            "
          >
            +
          </button>
        </div>
        <ng-template #elseEvaluatorBlock>
          <div (click)="piaEvaluatorNameFocusIn()">
            <input
              type="text"
              #piaEvaluatorName
              formControlName="evaluator_name"
              required
              placeholder="{{
                'homepage.cards.placeholder_evaluation' | translate
              }}"
              id="esg-edit-{{ pia.id }}-evaluator-name"
              (focusout)="piaEvaluatorNameFocusOut()"
            />
          </div>
        </ng-template>
      </div>
      <div>
        <label for="esg-edit-{{ pia.id }}-validator-name">{{
          "homepage.cards.validation" | translate
        }}</label>
        <div
          class="tag-input-container"
          *ngIf="authService.state; else elseValidatorBlock"
        >
          <tag-input
            #input
            id="esg-edit-{{ pia.id }}-validators"
            [disable]="
              authService.currentUserValue &&
              !authService.currentUserValue.access_type.includes('functional')
            "
            identifyBy="id"
            formControlName="validators"
            (onTextChange)="onTyped($event, pia.id, 'validators')"
            (onAdd)="onAddUser($event, 'validators')"
            (onRemove)="onRemove($event, 'validators')"
            [theme]="'foundation-theme'"
            [editable]="false"
            [clearOnBlur]="true"
            [placeholder]="'homepage.cards.placeholder_validation' | translate"
            [secondaryPlaceholder]="
              'homepage.cards.placeholder_validation' | translate
            "
          >
            <ng-template let-item="item" let-index="index">
              <div
                [ngClass]="{
                  'custom-tag': true,
                  'user-not-found': item.id == null
                }"
              >
                {{ item.display }}
                <delete-icon
                  *ngIf="
                    authService.currentUserValue.access_type.includes(
                      'functional'
                    )
                  "
                  (click)="input.removeItem(item, index)"
                ></delete-icon>
              </div>
            </ng-template>
            <tag-input-dropdown
              [keepOpen]="false"
              identifyBy="id"
              [showDropdownIfEmpty]="true"
              [autocompleteItems]="userList"
            ></tag-input-dropdown>
          </tag-input>
          <button
            type="button"
            class="btn btn-add-user"
            (click)="
              onAddUserWithIcon(
                {
                  display: addBtnForSpecificInput.display,
                  id: addBtnForSpecificInput.display
                },
                'validator_name'
              )
            "
            *ngIf="
              addBtnForSpecificInput &&
              addBtnForSpecificInput.pia_id === pia.id &&
              addBtnForSpecificInput.field === 'validator_name'
            "
          >
            +
          </button>
        </div>
        <ng-template #elseValidatorBlock>
          <div (click)="piaValidatorNameFocusIn()">
            <input
              type="text"
              #piaValidatorName
              formControlName="validator_name"
              required
              placeholder="{{
                'homepage.cards.placeholder_validation' | translate
              }}"
              id="esg-edit-{{ pia.id }}-validator-name"
              (focusout)="piaValidatorNameFocusOut()"
            />
          </div>
        </ng-template>
      </div>

      <div class="tag-input-container" *ngIf="authService.state">
        <label for="esg-edit-{{ pia.id }}-guest-name">{{
          "homepage.cards.guest" | translate
        }}</label>
        <tag-input
          #input
          class="esg-users-tags"
          id="esg-edit-{{ pia.id }}-guest-name"
          [disable]="
            authService.currentUserValue &&
            !authService.currentUserValue.access_type.includes('functional')
          "
          identifyBy="id"
          formControlName="guests"
          (onTextChange)="onTyped($event, pia.id, 'guests')"
          (onAdd)="onAddUser($event, 'guests')"
          (onRemove)="onRemove($event, 'guests')"
          [theme]="'foundation-theme'"
          [editable]="false"
          [clearOnBlur]="true"
          [placeholder]="'homepage.cards.placeholder_validation' | translate"
          [secondaryPlaceholder]="
            'homepage.cards.placeholder_validation' | translate
          "
        >
          <ng-template let-item="item" let-index="index">
            <div [ngClass]="{ 'custom-tag': true }">
              {{ item.display }}
              <delete-icon
                *ngIf="
                  authService.currentUserValue.access_type.includes(
                    'functional'
                  )
                "
                (click)="input.removeItem(item, index)"
              ></delete-icon>
            </div>
          </ng-template>
          <tag-input-dropdown
            [keepOpen]="false"
            identifyBy="id"
            [showDropdownIfEmpty]="true"
            [autocompleteItems]="usersForGuests"
          ></tag-input-dropdown>
        </tag-input>
        <button
          type="button"
          class="btn btn-add-user"
          (click)="
            onAddUserWithIcon(
              {
                display: addBtnForSpecificInput.display,
                id: addBtnForSpecificInput.display
              },
              'guests'
            )
          "
          *ngIf="
            addBtnForSpecificInput &&
            addBtnForSpecificInput.pia_id === pia.id &&
            addBtnForSpecificInput.field === 'guests'
          "
        >
          +
        </button>
      </div>

      <div (click)="piaCategoryFocusIn()">
        <label for="esg-edit-{{ pia.id }}-category">{{
          "homepage.cards.category" | translate
        }}</label>
        <input
          type="text"
          #piaCategory
          formControlName="category"
          placeholder="{{ 'homepage.cards.placeholder_category' | translate }}"
          id="esg-edit-{{ pia.id }}-category"
          (focusout)="piaCategoryFocusOut()"
        />
      </div>
      <div class="esg-cardsBlock-item-structure" *ngIf="pia.structure_id">
        <div>{{ "homepage.cards.structure" | translate }}</div>
        <span>{{ pia.structure_name }}</span>
      </div>
      <div class="esg-cardsBlock-item-date">
        <div>{{ "homepage.cards.date" | translate }}</div>
        <time>{{
          pia.created_at
            | date: "shortDate":"":languagesService.selectedLanguage
        }}</time>
      </div>
      <div class="esg-cardsBlock-item-status">
        <div class="esg-cardsBlock-item-status-infos">
          <div>{{ "homepage.cards.status" | translate }}</div>
          <div
            class="pendingValidation"
            *ngIf="
              pia.status === 0 && pia.progress === 100;
              else defaultStatuses
            "
          >
            {{ "esg.statuses.5" | translate }}
          </div>
          <ng-template #defaultStatuses>
            <div>{{ piaService.getStatusName(pia.status) | translate }}</div>
          </ng-template>
        </div>
        <div class="esg-cardsBlock-item-status-progressBar">
          <div>{{ pia.progress < 100 ? pia.progress : 100 }}%</div>
          <progress max="100" value="{{ pia.progress }}"></progress>
        </div>
      </div>
      <div class="esg-cardsBlock-item-btn">
        <div
          *ngIf="pia.status === 0 || pia.status === 1"
          class="esg-cardsBlock-item-btn-single"
        >
          <a
            *ngIf="pia.progress >= 96; else editBlock"
            [routerLink]="['/esg', pia.id, 'section', 1, 'item', 1]"
            title="{{ 'homepage.cards.item.edit_esg' | translate }}"
            class="btn btn-green"
            [class.disabled]="piaForm.invalid"
            >{{ "homepage.cards.item.validate_esg" | translate }}
          </a>
          <ng-template #editBlock>
            <a
              [routerLink]="['/esg', pia.id, 'section', 1, 'item', 1]"
              title="{{ 'homepage.cards.item.edit_esg' | translate }}"
              class="btn btn-green"
              [class.disabled]="piaForm.invalid"
              >{{ "homepage.cards.item.edit_esg" | translate }}</a
            >
          </ng-template>
        </div>
        <div
          *ngIf="pia.status != 0 && pia.status != 1"
          class="esg-cardsBlock-item-btn-single"
        >
          <a
            routerLink="/preview/{{ pia.id }}"
            title="{{ 'homepage.cards.item.consult_esg' | translate }}"
            class="btn btn-green"
            [class.disabled]="piaForm.invalid"
            >{{ "homepage.cards.item.consult_esg" | translate }}</a
          >
        </div>
      </div>
    </form>
  </div>
</div>
