<div class="esg-revisions hide-for-print">
  <div class="esg-revisions-title" *ngIf="title">
    <i class="fa fa-history"></i> {{ "summary.revision.revision" | translate }}
  </div>
  <div style="border-radius:10px;" class="esg-revisions-box" *ngIf="revisions">
    <h4>{{ "summary.revision.esg_version" | translate }}</h4>
    <p>
      <i class="fa fa-circle"></i>
      {{ currentVersion | date: "short":"":languagesService.selectedLanguage }}
      - {{ "summary.revision.current_version" | translate }}
    </p>
    <a
      *ngIf="revisions.length > 0"
      href="javascript;"
      class="esg-revisions-box-footer"
      (click)="opened = !opened; $event.preventDefault()"
    >
      <span>{{
        "summary.revision.show" | translate: { length: revisions.length }
      }}</span>
      <i class="fa fa-chevron-down" *ngIf="!opened; else close"></i>
      <ng-template #close>
        <i class="fa fa-chevron-up"></i>
      </ng-template>
    </a>
    <div
      *ngIf="opened && revisionsGroupByMonth"
      class="esg-revisions-box-content"
    >
      <div
        class="esg-revisions-box-content-revision"
        *ngFor="let date of revisionsGroupByMonthInArray.reverse()"
      >
        <h5>{{ date }}</h5>
        <ul>
          <li
            *ngFor="let revision of revisionsGroupByMonth[date]"
            class="esg-revisions-box-content-revision-item"
            [attr.revision-id]="revision.id"
          >
            <span
              (click)="
                previewRevision(revision.id, $event, revision.created_at)
              "
            >
              <i class="fa fa-circle-o"></i>
              {{
                revision.created_at
                  | date: "short":"":languagesService.selectedLanguage
              }}
            </span>
          </li>
        </ul>
      </div>
    </div>
    <button
      class="esg-revisions-new"
      *ngIf="
        ['author', 'validator', 'evaluator'].indexOf(editMode[0]) >= 0 ||
        editMode === 'local'
      "
      (click)="newRevision()"
    >
      <span class="esg-icons esg-icon-add-white"></span>
      {{ "summary.revision.save" | translate }}
    </button>
  </div>
</div>
<app-modal
  *ngIf="preview"
  (clickOnClose)="preview = null"
  specialClass="revision-preview-modal"
>
  <app-revision-preview
    *ngIf="preview"
    [revision]="preview"
    [editMode]="editMode"
  >
  </app-revision-preview>
</app-modal>
