<td class="esg-listsBlock-item">
  <div class="esg-listsBlock-item-actions">
    <a
      [routerLink]="['/knowledge_bases', base.id]"
      class="btn esg-tooltip"
      *ngIf="!base.is_example; else elseblock"
    >
      <i class="fa fa-pencil" aria-hidden="true"></i>
      <span
        title="{{ 'homepage.lists.item.tools.edit' | translate }}"
        class="esg-tooltip-text"
        >{{ "homepage.lists.item.tools.edit" | translate }}</span
      >
    </a>
    <ng-template #elseblock>
      <a [routerLink]="['/knowledge_bases', base.id]" class="btn esg-tooltip">
        <i class="fa fa-eye" aria-hidden="true"></i>
        <span
          title="{{ 'homepage.cards.item.consult_esg' | translate }}"
          class="esg-tooltip-text"
          >{{ "homepage.lists.item.tools.consult" | translate }}</span
        >
      </a>
    </ng-template>
    <a
      href="javascript:;"
      (click)="export(base.id)"
      class="btn esg-tooltip"
      *ngIf="!base.is_example"
    >
      <i class="fa fa-download" aria-hidden="true"></i>
      <span
        title="{{ 'homepage.lists.item.tools.export' | translate }}"
        class="esg-tooltip-text"
        >{{ "homepage.lists.item.tools.export" | translate }}</span
      >
    </a>
    <a
      href="javascript:;"
      (click)="duplicate(base.id)"
      class="btn esg-tooltip"
      *ngIf="!base.is_example"
    >
      <i class="fa-solid fa-copy" aria-hidden="true"></i>
      <span
        title="{{ 'homepage.lists.item.tools.duplicate' | translate }}"
        class="esg-tooltip-text"
        >{{ "homepage.lists.item.tools.duplicate" | translate }}</span
      >
    </a>
    <a
      href="javascript:;"
      (click)="remove(base.id)"
      class="btn esg-tooltip"
      *ngIf="!base.is_example"
    >
      <i class="fa-solid fa-trash" aria-hidden="true"></i>
      <span
        title="{{ 'homepage.lists.item.tools.remove' | translate }}"
        class="esg-tooltip-text"
        >{{ "homepage.lists.item.tools.remove" | translate }}</span
      >
    </a>
  </div>
</td>
<td class="esg-listsBlock-item">
  <div
    contenteditable
    (focusout)="onFocusOut('name', $event)"
    *ngIf="!base.is_example; else readOnlyName"
  >
    {{ base.name }}
  </div>
  <ng-template #readOnlyName>
    <div>{{ base.name }}</div>
  </ng-template>
</td>
<td class="esg-listsBlock-item">
  <div
    contenteditable
    (focusout)="onFocusOut('author', $event)"
    *ngIf="!base.is_example; else readOnlyName"
  >
    {{ base.author }}
  </div>
  <ng-template #readOnlySectorName>
    <div>{{ base.author }}</div>
  </ng-template>
</td>
<td class="esg-listsBlock-item">
  <div
    contenteditable
    (focusout)="onFocusOut('contributors', $event)"
    *ngIf="!base.is_example; else readOnlyName"
  >
    {{ base.contributors }}
  </div>
  <ng-template #readOnlySectorName>
    <div>{{ base.contributors }}</div>
  </ng-template>
</td>
<td class="esg-listsBlock-item">
  <div>{{ nbEntries }}</div>
</td>
