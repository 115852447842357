<section
  class="esg-entryContentBlock esg-entryContentBlock-section{{ section.id }}"
  *ngIf="section && item && editMode"
>
  <header
    style="border-radius: 10px;"
    autoscroll="true"
    class="esg-entryContentBlock-header"
    style="border-radius:10px;"
  >
    <div style="border-top-left-radius: 10px; border-bottom-left-radius: 10px;">
      <i
        class="fa"
        *ngIf="
          sidStatusService.sidStatusIcon[
            sidStatusService.itemStatus[section.id + '.' + item.id]
          ];
          else specialIcon
        "
        [ngClass]="
          sidStatusService.sidStatusIcon[
            sidStatusService.itemStatus[section.id + '.' + item.id]
          ]
        "
        aria-hidden="true"
      ></i>
      <i
        class="fa"
        #specialIcon
        *ngIf="sidStatusService.specialIcon[section.id + '.' + item.id]"
        [ngClass]="sidStatusService.specialIcon[section.id + '.' + item.id]"
        aria-hidden="true"
      ></i>
      <div class="esg-entryContentBlock-header-progress">
        <div
          *ngIf="sidStatusService.itemStatus[section.id + '.' + item.id] <= 4"
        >
          {{ "esg.header.edition" | translate }}
        </div>
        <div
          *ngIf="
            sidStatusService.itemStatus[section.id + '.' + item.id] > 4 &&
            sidStatusService.itemStatus[section.id + '.' + item.id] <= 7
          "
        >
          {{ "esg.header.evaluation" | translate }}
        </div>
      </div>
    </div>
    <div class="esg-entryContentBlock-header-title">
      <h1 *ngIf="section.title">{{ section.title | translate }}</h1>
      <h3 *ngIf="section.short_help">{{ section.short_help | translate }}</h3>
      <h2 *ngIf="item.title">{{ item.title | translate }}</h2>
      <h4 *ngIf="item.short_help">{{ item.short_help | translate }}</h4>
    </div>
    <a
      *ngIf="pia.is_example === 1 || (pia.status != 0 && pia.status != 1)"
      routerLink="/preview/{{ pia.id }}"
      class="btn esg-previewBlock"
    >
      <span class="esg-icons esg-icon-preview"></span>
      <div
        *ngIf="section.id === 4 && item.id === 2; else defaultBlock"
        [innerHTML]="'esg.header.display_action_plan' | translate"
      ></div>
      <ng-template #defaultBlock>
        <div>{{ "esg.header.preview" | translate }}</div>
      </ng-template>
    </a>
  </header>

  <!-- Back to edition -->
  <div
    style="border-radius:10px;"
    class="esg-status-info evaluationsStatus"
    *ngIf="
      globalEvaluationService.status === 4 &&
      pia.is_example !== 1 &&
      (editMode.includes('author') || editMode === 'local')
    "
  >
    <div>
      <strong>{{ "esg.sections.status.evaluation.title" | translate }}</strong>
      <p>
        <span
          [innerHTML]="'esg.sections.status.evaluation.content' | translate"
        ></span>
        <a
          href="javascript:;"
          (click)="cancelAskForEvaluation()"
          [innerHTML]="'esg.sections.status.evaluation.cancel' | translate"
        ></a>
      </p>
    </div>
  </div>

  <!-- Back to evaluation -->
  <div
    style="border-radius:10px;"
    class="esg-status-info"
    *ngIf="
      globalEvaluationService.status === 7 &&
      pia.is_example !== 1 &&
      !(section.id == 4 && item.id == 3) &&
      (editMode.includes('evaluator') || editMode === 'local')
    "
  >
    <div>
      <strong>{{ "esg.sections.status.validation.title" | translate }}</strong>
      <p>
        <span
          [innerHTML]="'esg.sections.status.validation.content' | translate"
        ></span>
        <a
          href="javascript:;"
          (click)="cancelValidateEvaluation()"
          [innerHTML]="'esg.sections.status.validation.cancel' | translate"
        ></a>
      </p>
    </div>
  </div>

  <ng-container *ngIf="!loading; else elseTemplate">
    <ng-container *ngIf="item.is_measure; else appQuestions">
      <button
        (click)="onAddNewMeasure()"
        class="btn btn-white btn-addMeasure"
        type="button"
        *ngIf="
          globalEvaluationService.answerEditionEnabled &&
          (editMode.includes('author') || editMode === 'local')
        "
      >
        <span class="esg-icons esg-icon-add"></span>
        {{ "measures.add" | translate }}
      </button>
      <app-measures
        *ngFor="let measure of measures"
        [editMode]="editMode"
        [measure]="measure"
        [pia]="pia"
        [section]="section"
        [item]="item"
        (deleted)="onDeletedMeasure($event)"
      >
      </app-measures>
    </ng-container>
    <ng-template #appQuestions>
      <app-questions
        [editMode]="editMode"
        *ngFor="let question of questions"
        [question]="question"
        [pia]="pia"
        [section]="section"
        [item]="item"
      >
      </app-questions>
    </ng-template>
  </ng-container>
  <ng-template #elseTemplate>
    <div
      class="esg-sectionBlock-body"
      style="position:relative; height: 150px;"
    >
      <app-loading-overlay
        [visibility]="true"
        [childMode]="true"
      ></app-loading-overlay>
    </div>
  </ng-template>

  <app-evaluations
    [editMode]="editMode"
    *ngIf="item.evaluation_mode === 'item'"
    [pia]="pia"
    [section]="section"
    [item]="item"
  >
  </app-evaluations>

  <div
    [pia]="pia"
    class="app-overview-risks"
    *ngIf="section.id == 3 && item.id == 5"
  ></div>

  <app-validate-pia
    [editMode]="editMode"
    [pia]="pia"
    *ngIf="section.id == 4 && item.id == 4"
  ></app-validate-pia>

  <app-refuse-pia
    [editMode]="editMode"
    [pia]="pia"
    *ngIf="section.id == 4 && item.id == 5"
  ></app-refuse-pia>

  <div
    [pia]="pia"
    class="app-risks-cartography"
    *ngIf="section.id == 4 && item.id == 1"
  ></div>

  <app-action-plan
    *ngIf="section.id == 4 && item.id == 2"
    [pia]="pia"
    [data]="data"
  ></app-action-plan>

  <app-dpo-people-opinions
    [editMode]="editMode"
    [pia]="pia"
    *ngIf="section.id == 4 && item.id == 3"
  ></app-dpo-people-opinions>

  <footer class="esg-entryContentBlock-footer">
    <div *ngIf="globalEvaluationService.status > 0">
      <button
        class="btn btn-green"
        (click)="prepareForEvaluation()"
        *ngIf="
          (globalEvaluationService.status === 1 ||
            globalEvaluationService.status === 2 ||
            globalEvaluationService.status === 3) &&
          (editMode.includes('author') || editMode === 'local')
        "
        [ngClass]="{
          'btn-active':
            globalEvaluationService.status === 2 ||
            globalEvaluationService.status === 3
        }"
        [disabled]="globalEvaluationService.status === 1"
      >
        {{ "esg.footer.ask_evaluation" | translate }}
      </button>

      <button
        class="btn btn-green"
        (click)="validateEvaluation()"
        *ngIf="
          (globalEvaluationService.status === 5 ||
            globalEvaluationService.status === 6) &&
          (editMode.includes('evaluator') || editMode === 'local')
        "
        [ngClass]="{ 'btn-active': globalEvaluationService.status === 6 }"
        [disabled]="globalEvaluationService.status === 5"
      >
        {{ "esg.footer.validate_evaluation" | translate }}
      </button>

      <button
        class="btn btn-pending-validation"
        disabled
        *ngIf="
          globalEvaluationService.status === 7 &&
          !(section.id == 4 && item.id == 3)
        "
      >
        <i class="fa fa-check-square-o" aria-hidden="true"></i>
        {{ "esg.footer.subsection_validated" | translate }}
      </button>

      <p class="esg-entryContentBlock-footer-requiredFields">
        {{ "esg.footer.mandatory_fields" | translate }}
      </p>

      <button
        class="btn esg-cancelBtn"
        (click)="cancelAskForEvaluation()"
        *ngIf="
          pia.is_example !== 1 &&
          globalEvaluationService.status === 4 &&
          (editMode.includes('author') || editMode === 'local')
        "
      >
        {{ "esg.sections.status.evaluation.cancel_button" | translate }}
      </button>

      <button
        class="btn esg-cancelBtn"
        (click)="cancelValidateEvaluation()"
        *ngIf="
          pia.is_example !== 1 &&
          globalEvaluationService.status === 7 &&
          !(section.id == 4 && item.id == 3) &&
          (editMode.includes('evaluator') || editMode === 'local')
        "
      >
        {{ "esg.sections.status.validation.cancel_button" | translate }}
      </button>
    </div>

    <div class="esg-entryContentBlock-footer-nav">
      <a
        [ngClass]="{ mask: paginationService.hasPreviousLink === false }"
        class="esg-previousLink"
        routerLink="/esg/{{ pia.id }}/section/{{
          paginationService.previousLink[0]
        }}/item/{{ paginationService.previousLink[1] }}"
      >
        <i class="fa fa-angle-double-left" aria-hidden="true"></i>
        {{ paginationService.previousLink[2] | translate }} </a
      ><a
        [ngClass]="{ mask: paginationService.hasNextLink === false }"
        class="esg-nextLink"
        routerLink="/esg/{{ pia.id }}/section/{{
          paginationService.nextLink[0]
        }}/item/{{ paginationService.nextLink[1] }}"
      >
        {{ paginationService.nextLink[2] | translate }}
        <i class="fa fa-angle-double-right" aria-hidden="true"></i>
      </a>
    </div>
  </footer>
</section>
