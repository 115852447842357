<form style="border-radius: 10px;" [formGroup]="forgetPassword" (ngSubmit)="ngOnSubmit()">
<!-- class="esg-authenticationBlock-login" -->

<!-- <fieldset>
    <div
      class="form-group"
      [class.is-not-empty]="f.email.value.length > 0"
      [class.is-invalid]="f.email.value.length > 0 && f.email.hasError('email')"
    >
      <legend>{{ "authentication.account_recovery" | translate }}</legend>
      <p class="msg" *ngIf="msgFromBack">
        {{
          "authentication.errors.account_recovery.email." + msgFromBack
            | translate
        }}
      </p>
      <input
        type="text"
        placeholder="{{ 'authentication.email_placeholder' | translate }}"
        id="esg-account-email"
        formControlName="email"
        email
      />
      <label
        *ngIf="f.email.hasError('email'); else emailBlock"
        for="esg-account-email"
      >
        {{ "authentication.wrong_email" | translate }}
      </label>
      <ng-template #emailBlock>
        <label style="color: #775ac7" for="esg-account-email">
          {{ "authentication.email" | translate }}
        </label>
      </ng-template>
    </div>
    <div class="validForm">
      <button style="color: #775ac7 !important;" (click)="onCanceled()">
        {{ "authentication.cancel" | translate }}
      </button>
      <button
        [disabled]="forgetPassword.invalid || loading"
        type="submit"
        class="btn btn-green"
      >
        <ng-container *ngIf="loading; else loadingElse">
          ...
        </ng-container>
        <ng-template #loadingElse>
          {{ "authentication.continue" | translate }}
        </ng-template>
      </button>
    </div>
  </fieldset> -->



<div style="display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: auto;
      height: 100vh;">
    <span style="font-size: 24px; font-weight:600;text-align:center;margin-bottom:7px">{{
      "authentication.account_recovery" | translate }}
    </span>
    <span class="msg" *ngIf="msgFromBack" style="font-size: 13px; font-weight:400;text-align:center;color:#71717A"> {{
      "authentication.errors.account_recovery.email." + msgFromBack
      | translate
      }}
    </span>

    <div style="display: flex;
        flex-direction: column;
        gap: 7px;
        width: 50%;
        margin-top: 24px;">

      <label style="font-weight: 600;" *ngIf="f.email.hasError('email'); else emailBlock" for="esg-account-email">
        {{ "authentication.wrong_email" | translate }}
      </label>
      <ng-template #emailBlock>
        <label style="color: #775ac7" for="esg-account-email">
          {{ "authentication.email" | translate }}
        </label>
      </ng-template>
      <input type="text" placeholder="{{ 'authentication.email_placeholder' | translate }}" id="esg-account-email"
        formControlName="email" email
        style="padding:11.25px 13px 11.25px 13px;outline-width: 0;border:1px solid #E4E4E7;border-radius: 6px;" type="text"
        placeholder="https://">
    </div>


    <div style="display: flex;
        flex-direction: column;
        gap: 7px;
        width: 50%;
        margin-top: 24px;">
      <button class="button-auth-primary" [disabled]="forgetPassword.invalid || loading" type="submit">
        <ng-container *ngIf="loading; else loadingElse">
          ...
        </ng-container>
        <ng-template #loadingElse>
          {{ "authentication.continue" | translate }}
        </ng-template>
      </button>

      <button class="button-auth-outline" (click)="onCanceled()">
        {{ "authentication.cancel" | translate }}
      </button>
    </div>
  </div>


</form>



