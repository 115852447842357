<div class="esg-modalBlock open">
  <div style="overflow: auto;" class="esg-modalBlock-content">
    <button
      tabindex="1"
      type="button"
      class="esg-modalBlock-close btn"
      title="{{ 'homepage.cards.title_close_creation' | translate }}"
      (click)="clickOnClose.emit()"
    >
      <span class="esg-icons esg-icon-close"></span>
    </button>
    <ng-content></ng-content>
  </div>
</div>
