<td class="esg-listsBlock-item">
  <div class="esg-listsBlock-item-actions">
    <a [routerLink]="['/preview', archivedPia.id]" class="btn esg-tooltip">
      <i class="fa fa-eye" aria-hidden="true"></i>
      <span
        title="{{ 'homepage.lists.item.tools.consult' | translate }}"
        class="esg-tooltip-text"
        >{{ "homepage.lists.item.tools.consult" | translate }}</span
      >
    </a>
    <a
      href="javascript:;"
      (click)="unarchive(archivedPia.id)"
      class="btn esg-tooltip"
    >
      <i class="fa fa-refresh" aria-hidden="true"></i>
      <span
        title="{{ 'homepage.lists.item.tools.unarchive' | translate }}"
        class="esg-tooltip-text"
        >{{ "homepage.lists.item.tools.unarchive" | translate }}</span
      >
    </a>
    <a
      href="javascript:;"
      (click)="remove(archivedPia.id)"
      class="btn esg-tooltip"
    >
      <i class="fa-solid fa-trash" aria-hidden="true"></i>
      <span
        title="{{ 'homepage.lists.item.tools.remove' | translate }}"
        class="esg-tooltip-text"
        >{{ "homepage.lists.item.tools.remove" | translate }}</span
      >
    </a>
  </div>
</td>
<td class="esg-listsBlock-item esg-listsBlock-item-title">
  <div>{{ archivedPia.name }}</div>
  <div
    *ngIf="attachments && attachments.length > 0"
    class="esg-listsBlock-item-attachment"
    aria-hidden="true"
  >
    <span class="esg-icons esg-icon-attachment"></span>
    <ul>
      <ng-container *ngFor="let attachment of attachments">
        <li>{{ attachment.name }}</li>
      </ng-container>
    </ul>
  </div>
</td>
<td class="esg-listsBlock-item">
  <time
    *ngIf="
      archivedPia.updated_at &&
      archivedPia.updated_at.toString() !== 'Invalid Date'
    "
    >{{
      archivedPia.updated_at
        | date: "shortDate":"":languagesService.selectedLanguage
    }}</time
  >
</td>
<td class="esg-listsBlock-item">
  <div>{{ archivedPia.author_name }}</div>
</td>
<td class="esg-listsBlock-item">
  <div>{{ archivedPia.evaluator_name }}</div>
</td>
<td class="esg-listsBlock-item">
  <div>{{ archivedPia.validator_name }}</div>
</td>
<td class="esg-listsBlock-item">
  <div *ngIf="archivedPia.structure_id">{{ archivedPia.structure_name }}</div>
</td>
<td class="esg-listsBlock-item progress">
  <div class="esg-cardsBlock-item-status-progressBar">
    <span title="{{ piaService.getStatusName(archivedPia) | translate }}">{{
      piaService.getStatusName(archivedPia) | translate
    }}</span>
    <progress max="100" value="{{ archivedPia.progress }}"></progress>
  </div>
</td>
