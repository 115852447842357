<div class="esg-knowledgeBaseBlock">
  <h3 style="font-weight:400;">
    {{ "knowledge_base.choose_knowledge_base" | translate }}
  </h3>

  <select
    style="height: 37px;"
    *ngIf="customKnowledgeBases"
    (change)="switch(selectedKnowledBase)"
    [(ngModel)]="selectedKnowledBase"
  >
    <option [value]="base.id" *ngFor="let base of customKnowledgeBases">{{
      base.name
    }}</option>
  </select>

  <br /><br />
  <div class="esg-knowledgeBaseBlock-searchForm">
    <form (ngSubmit)="onSubmit()" [formGroup]="searchForm">
      <input style="border-radius:10px;" type="search" formControlName="q" />
      <button
        class="btn"
        type="submit"
        title="{{ 'knowledge_base.search' | translate }}"
      >
        <i class="fa fa-search" aria-hidden="true"></i>
      </button>
    </form>
    <div
      *ngIf="knowledgeBaseService.placeholder"
      class="esg-knowledgeBaseBlock-placeholder bounceIn"
    >
      <div>{{ "knowledge_base.placeholder_help" | translate }}</div>
      <div>{{ knowledgeBaseService.placeholder | translate }}</div>
    </div>
  </div>

  <div
    class="esg-knowledgeBaseBlock-filters"
    *ngIf="item && item.filter_by === 'measure'"
  >
    <div>{{ "knowledge_base.filters.title" | translate }}</div>
    <button
      class="btn active"
      type="button"
      (click)="knowledgeBaseService.search('', $event)"
    >
      {{ "knowledge_base.filters.all" | translate }}
    </button>
    <button
      class="btn"
      type="button"
      (click)="knowledgeBaseService.search('measure.definition', $event)"
    >
      {{ "knowledge_base.filters.definitions" | translate }}
    </button>
    <button
      class="btn"
      type="button"
      (click)="knowledgeBaseService.search('measure.governance', $event)"
    >
      {{ "knowledge_base.filters.organizational_measures" | translate }}
    </button>
    <button
      class="btn"
      type="button"
      (click)="knowledgeBaseService.search('measure.data_processing', $event)"
    >
      {{ "knowledge_base.filters.measures_on_data" | translate }}
    </button>
    <button
      class="btn"
      type="button"
      (click)="knowledgeBaseService.search('measure.security', $event)"
    >
      {{ "knowledge_base.filters.general_measures" | translate }}
    </button>
  </div>

  <div
    *ngIf="
      knowledgeBaseService.knowledgeBaseData.length > 0;
      else noKnowledgeBaseData
    "
    class="esg-knowledgeBaseBlock-list"
  >
    <app-knowledge-base-item
      *ngFor="let itemKb of knowledgeBaseService.knowledgeBaseData"
      [item]="item"
      [itemKb]="itemKb"
      [editMode]="editMode"
      (newMeasureEvent)="addNewMeasure($event)"
    ></app-knowledge-base-item>
  </div>
  <ng-template #noKnowledgeBaseData>
    <div class="esg-knowledgeBaseBlock-list">
      {{ "knowledge_base.filters.no_result" | translate }}
    </div>
  </ng-template>
</div>
