<!-- <section class="container esg-settingsBlock esg-mainContainerBlock">
  <div class="row">
    <div class="esg-breadcrumb">
      <strong
        >{{ "header.tools.settings" | translate }} >
        {{ "header.tools.url_setting" | translate }}</strong
      >
    </div>

    <div class="small-12 medium-12 columns">
      <div class="esg-settingsBlock-server">
        <h1>{{ "settings.title" | translate }}</h1>
        <form [formGroup]="settingsForm" (ngSubmit)="onSubmit()" novalidate>
          <label for="server_url">{{ "settings.content" | translate }}</label>
          <input
            type="text"
            id="server_url"
            formControlName="server_url"
            placeholder="https://"
            (blur)="purify('server_url', $event)"
          />
          <label for="client_id">Client ID :</label>
          <input
            type="text"
            id="client_id"
            formControlName="client_id"
            placeholder="Client ID"
            (blur)="purify('client_id', $event)"
          />
          <label for="client_secret">Client SECRET :</label>
          <input
            type="password"
            id="client_secret"
            formControlName="client_secret"
            placeholder="Client SECRET"
            (blur)="purify('client_secret', $event)"
          />
          <button
            *ngIf="!loading; else loadingElse"
            type="submit"
            class="btn btn-green"
            ngClass="{'btn--processing': formProcessing}"
          >
            {{ "settings.update" | translate }}
          </button>
          <ng-template #loadingElse>
            <div
              style="margin: auto; text-align: center; position:relative; height: 150px;"
            >
              <app-loading-overlay
                [visibility]="true"
                [childMode]="true"
              ></app-loading-overlay>
            </div>
          </ng-template>
        </form>
      </div>
    </div>
  </div>
</section>
<app-dialog></app-dialog> -->




<section style="display: grid;grid-template-columns: repeat(2, minmax(0, 1fr));height: 100vh;">

  <div
    style="position:relative;background-image: url('assets/images/esg-bg-auth.png');background-repeat: no-repeat;background-size: cover;">
    <img style="position: absolute;left:43px;top:43px;z-index:9999" src="assets/images/esg-logo-text.svg"
      alt="Logo ESG" />
    <p style="
    color: #FFFFFF;
    position: absolute;
    bottom: 21px;
    font-family: Inter;
    font-style: italic;
    line-height: 24px;
    font-weight: 400;
    font-size: 16.44px;
    text-align: justify;
    width: 90%;
    left: 23px;
    ">{{ "authentication.side_text" |
      translate }}</p>
  </div>
  <div style="background-color: white;">

    <!-- Setting design -->
    <div style="display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: auto;
      height: 100vh;">
      <span style="font-size: 24px; font-weight:600;text-align:center;margin-bottom:7px">{{ "header.tools.settings" |
        translate }}
      </span>
      <span style="font-size: 13px; font-weight:400;text-align:center;color:#71717A">{{ "header.tools.url_setting" |
        translate }}
      </span>
      <form style="width: 50%" [formGroup]="settingsForm" (ngSubmit)="onSubmit()" novalidate>

        <div style="display: flex;
        flex-direction: column;
        gap: 7px;
        margin-top: 24px;">
          <label for="server_url" style="font-weight: 600;">{{ "settings.content" | translate }}</label>
          <input type="text" id="server_url" formControlName="server_url" (blur)="purify('server_url', $event)"
            style="padding:11.25px 13px 11.25px 13px;outline-width: 0;border:1px solid #E4E4E7;border-radius: 6px;"
            type="text" placeholder="https://">
        </div>

        <div style="display: flex;
        flex-direction: column;
        gap: 7px;
        margin-top: 24px;">
          <label for="client_id" style="font-weight: 600;">Client ID</label>
          <input type="text" id="client_id" formControlName="client_id" placeholder="Client ID"
            (blur)="purify('client_id', $event)"
            style="padding:11.25px 13px 11.25px 13px;outline-width: 0;border:1px solid #E4E4E7;border-radius: 6px;"
            placeholder="Client id">
        </div>

        <div style="display: flex;
        flex-direction: column;
        gap: 7px;
        margin-top: 24px;">
          <label for="client_secret" style="font-weight: 600;">Client Secret Key</label>
          <input type="password" id="client_secret" formControlName="client_secret" placeholder="Client Secret Key"
            (blur)="purify('client_secret', $event)"
            style="padding:11.25px 13px 11.25px 13px;outline-width: 0;border:1px solid #E4E4E7;border-radius: 6px;"
            placeholder="Client Secret">
        </div>


        <div style="display: flex;
        flex-direction: column;
        gap: 7px;
        margin-top: 24px;">
          <button *ngIf="!loading; else loadingElse" type="submit" ngClass="{'btn--processing': formProcessing}"
            class="button-auth-primary"> {{ "settings.update" | translate }}
          </button>
          <a routerLink="/" style="text-decoration: none;" class="button-auth-outline">{{ "authentication.cancel" |
            translate }}</a>
          <ng-template #loadingElse>
            <div style="margin: auto; text-align: center; position:relative; height: 150px;">
              <app-loading-overlay [visibility]="true" [childMode]="true"></app-loading-overlay>
            </div>
          </ng-template>
        </div>
      </form>

    </div>



  </div>
</section>


<app-dialog></app-dialog>