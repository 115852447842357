<form
  style="border-radius: 10px;"
  class="esg-authenticationBlock-login"
  [formGroup]="signUp"
  (ngSubmit)="ngOnSubmit()"
>
  <fieldset>
    <div class="form-group" [class.is-not-empty]="f.password.value.length > 0">
      <legend *ngIf="reset; else noReset">
        {{ "authentication.account_recovery" | translate }}
      </legend>
      <ng-template #noReset>
        <legend>
          {{ "authentication.activate_account" | translate }}
        </legend>
      </ng-template>
      <p class="msg" *ngIf="msgFromBack">
        {{
          "authentication.errors." +
            (reset ? "account_recovery" : "create_account") +
            ".password." +
            msgFromBack | translate
        }}
      </p>
      <input
        type="password"
        placeholder="{{ 'authentication.password' | translate }}"
        id="esg-account-password"
        formControlName="password"
      />
      <label style="color: #775ac7 !important;" for="esg-account-password">
        {{ "authentication.password" | translate }}
      </label>
    </div>

    <div
      [class.is-valid]="
        f.confirmPassword.value.length > 0 && !f.confirmPassword.errors
      "
      [class.is-invalid]="
        f.confirmPassword.value.length > 0 && f.confirmPassword.errors
      "
      [class.is-not-empty]="f.confirmPassword.value.length > 0"
    >
      <input
        type="password"
        placeholder="{{ 'authentication.confirmation' | translate }}"
        id="esg-account-confirmPassword"
        formControlName="confirmPassword"
        disabled
      />
      <label
        *ngIf="
          f.confirmPassword.value.length > 0 && f.confirmPassword.errors;
          else confirmationBlock
        "
        for="esg-account-confirmPassword"
      >
        {{ "authentication.wrong_confirmation" | translate }}
      </label>
      <ng-template #confirmationBlock>
        <label for="esg-account-confirmPassword">
          {{ "authentication.confirmation" | translate }}
        </label>
      </ng-template>
    </div>
    <div>
      <br />
      <label id="securityPassword">{{
        "authentication.password_security" | translate
      }}</label>
      <p
        [class.is-valid]="
          f.password.value.length > 0 &&
          !signUp.controls['password'].hasError('minlength')
        "
        [class.is-invalid]="
          f.password.value.length > 0 &&
          signUp.controls['password'].hasError('minlength')
        "
      >
        <input
          type="checkbox"
          disabled="true"
          [checked]="
            f.password.value.length > 0 &&
            !signUp.controls['password'].hasError('minlength')
          "
        /><span>{{ "authentication.12_characters" | translate }}</span>
      </p>
      <p
        [class.is-valid]="
          f.password.value.length > 0 &&
          !signUp.controls['password'].hasError('hasCapitalCase')
        "
        [class.is-invalid]="
          f.password.value.length > 0 &&
          signUp.controls['password'].hasError('hasCapitalCase')
        "
      >
        <input
          type="checkbox"
          disabled="true"
          [checked]="
            f.password.value.length > 0 &&
            !signUp.controls['password'].hasError('hasCapitalCase')
          "
        /><span>{{ "authentication.uppercase" | translate }}</span>
      </p>
      <p
        [class.is-valid]="
          f.password.value.length > 0 &&
          !signUp.controls['password'].hasError('hasSmallCase')
        "
        [class.is-invalid]="
          f.password.value.length > 0 &&
          signUp.controls['password'].hasError('hasSmallCase')
        "
      >
        <input
          type="checkbox"
          disabled="true"
          [checked]="
            f.password.value.length > 0 &&
            !signUp.controls['password'].hasError('hasSmallCase')
          "
        /><span>{{ "authentication.lowercase" | translate }}</span>
      </p>
      <p
        [class.is-valid]="
          f.password.value.length > 0 &&
          !signUp.controls['password'].hasError('hashSpecialChar')
        "
        [class.is-invalid]="
          f.password.value.length > 0 &&
          signUp.controls['password'].hasError('hashSpecialChar')
        "
      >
        <input
          type="checkbox"
          disabled="true"
          [checked]="
            f.password.value.length > 0 &&
            !signUp.controls['password'].hasError('hashSpecialChar')
          "
        /><span>{{ "authentication.special_character" | translate }}</span>
      </p>
      <p
        [class.is-valid]="
          f.password.value.length > 0 &&
          !signUp.controls['password'].hasError('hasNumber')
        "
        [class.is-invalid]="
          f.password.value.length > 0 &&
          signUp.controls['password'].hasError('hasNumber')
        "
      >
        <input
          type="checkbox"
          disabled="true"
          [checked]="
            f.password.value.length > 0 &&
            !signUp.controls['password'].hasError('hasNumber')
          "
        /><span>{{ "authentication.number" | translate }}</span>
      </p>
    </div>
    <div class="validForm">
      <button type="reset" (click)="onCanceled()">
        {{ "authentication.cancel" | translate }}
      </button>
      <button
        [disabled]="signUp.invalid || loading"
        type="submit"
        class="btn btn-green"
      >
        <ng-container *ngIf="loading; else loadingElse">
          ...
        </ng-container>
        <ng-template #loadingElse>
          <ng-container *ngIf="reset; else noReset">
            {{ "authentication.confirm" | translate }}
          </ng-container>
          <ng-template #noReset>
            {{ "authentication.activate_account" | translate }}
          </ng-template>
        </ng-template>
      </button>
    </div>
  </fieldset>
</form>
