<!-- <div class="hide-for-print esg-versionInfoBlock">Version {{ appVersion }}</div> -->
<header class="esg-headerBlock hide-for-print">
  <!-- <pre>{{appDataService.entrieMode}}</pre> -->

  <div class="esg-logoBlock">
    <!-- <div style="display:flex;align-items:center;justify-content:space-around"> -->
    <div>
      <a routerLink="/entries"
        ><img src="assets/images/esg_logo2.svg" alt="ESG Logo"
      /></a>
      <div style="margin-left:10px !important">
        <!-- {{ "header.logo_title" | translate }}<br /><span>{{
          "header.logo_subtitle" | translate
          }}</span> -->
      </div>
      <!-- <div class="hide-for-print esg-closeFullScreenModeAlertBlock">
          {{ "header.exit_full_screen_mode" | translate }}
        </div> -->
    </div>
    <!-- dropdown -->
    <ul
      style="margin-bottom: 15px;"
      class="esg-navigationBlock-list "
      *ngIf="
        (!authService.state && router.url !== '/') ||
        authService.currentUserValue !== null
      "
    >
      <li
        class="esg-drop-li esg-navigationBlock-withsub esg-navigationBlock-dropdown esg-navigationBlock-esg"
      >
        <a
          [ngClass]="{
            'active-nav-header':
              appDataService.entrieMode === 'pia' ||
              appDataService.entrieMode === 'archive'
          }"
          href="javascript:;"
        >
          <i class="fas fa-check-double"></i>
          {{ "header.homepage_link" | translate }}
          <!-- <span class="esg-icons esg-icon-scroll" aria-hidden="true"></span> -->
          <i style="font-size: 10px;" class="fa-solid fa-chevron-down"></i>
        </a>
        <ul>
          <li>
            <a [routerLink]="['entries']">{{
              "header.current_esg" | translate
            }}</a>
          </li>
          <li>
            <a [routerLink]="['entries', 'archive']">{{
              "header.archives" | translate
            }}</a>
          </li>
        </ul>
      </li>
      <li class="esg-drop-li esg-navigationBlock-noSub">
        <a
          [ngClass]="{
            'active-nav-header': appDataService.entrieMode === 'structure'
          }"
          style="text-transform:uppercase;"
          [routerLink]="['entries', 'structure']"
          ><i class="fa-solid fa-folder"></i
          >{{ "header.structures" | translate }}</a
        >
      </li>
      <li class="esg-drop-li esg-navigationBlock-noSub">
        <a
          [ngClass]="{
            'active-nav-header': appDataService.entrieMode === 'knowledgeBase'
          }"
          style="text-transform:uppercase;"
          [routerLink]="['entries', 'knowledge_bases']"
          ><i class="fa-solid fa-brain"></i
          >{{ "header.knowledge_base_link" | translate }}</a
        >
      </li>
    </ul>
    <!-- <a class="btn btn-reservation-esg-outline">
      <div style="padding: 7px;display:flex;gap:6px">
        <img src="assets/images/calendly-logo.svg" alt="Logo calendly" />Réserver un appel
      </div>
    </a> -->

    <div style="display:flex;align-items:center">
      <button
        *ngIf="
          !authService.state &&
          !authService.currentUserValue &&
          router.url !== '/'
        "
        type="button"
        class="btn btn-reservation-esg-outline"
        (click)="showModalReservation = true"
      >
        <div>
          <img src="assets/images/calendly-logo.svg" alt="Logo calendly" />
          <span
            style="font-size: 13px;
    color: #006BFF;"
          >
            {{ "header.reserve_modal.btn" | translate }}
          </span>
        </div>
      </button>
      <!-- dropdown 2 -->
      <ul style="margin-bottom: 15px;" class="esg-navigationBlock-list">
        <ng-container>
          <li
            class="esg-drop-li esg-navigationBlock-withsub esg-navigationBlock-dropdown esg-navigationBlock-company"
          >
            <a href="javascript:;">
              <i
                class="fa fa-user"
                style="display:none;"
                aria-hidden="true"
              ></i>
              <i class="fa-solid fa-gear"></i>
              <!-- {{ "header.tools.settings" | translate }} -->
              <!-- <span class="esg-icons esg-icon-scroll" aria-hidden="true"></span> -->
              <i style="font-size: 10px;" class="fa-solid fa-chevron-down"></i>
            </a>
            <form class="drop-settings">
              <fieldset>
                <div
                  *ngIf="
                    authService.state &&
                    authService.currentUserValue !== null &&
                    authService.currentUserValue.access_type.includes(
                      'technical'
                    )
                  "
                >
                  <a routerLink="/users">{{
                    "header.tools.users" | translate
                  }}</a>
                </div>
                <div>
                  <a routerLink="/url">{{
                    "header.tools.url_setting" | translate
                  }}</a>
                </div>
                <!-- <div>
                  <input type="checkbox" name="esg-accessibility" id="esg-accessibility"
                    [checked]="appDataService.contrastMode" (change)="changeContrast($event)" />
                  <label for="esg-accessibility">{{
                    "header.tools.accessibility" | translate
                    }}</label>
                </div> -->
                <!-- <div>
                <a routerLink="/about">{{
                  "header.tools.about" | translate
                }}</a>
              </div> -->
                <div
                  *ngIf="
                    authService.state && authService.currentUserValue !== null
                  "
                >
                  <a (click)="disconnectUser()">{{
                    "authentication.sign_out_button" | translate
                  }}</a>
                </div>
              </fieldset>
            </form>
          </li>
          <li
            class="esg-drop-li esg-navigationBlock-withsub esg-navigationBlock-dropdown esg-navigationBlock-help"
          >
            <a href="javascript:;">
              <!-- {{ "header.help.title" | translate }} -->
              <i class="fa-solid fa-question"></i>
              <!-- <span class="esg-icons esg-icon-scroll" aria-hidden="true"></span> -->
              <i style="font-size: 10px;" class="fa-solid fa-chevron-down"></i>
            </a>
            <ul style="width: 21rem !important; margin-top: 10px !important;">
              <li
                *ngIf="
                  (languagesService.selectedLanguage === 'fr' &&
                    !authService.state &&
                    router.url !== '/') ||
                  authService.currentUserValue !== null
                "
              >
                <a (click)="goToExample()">
                  {{ "header.help.esg_example_link" | translate }}
                </a>
              </li>
              <li>
                <a routerLink="/help">{{ "header.help.manual" | translate }}</a>
              </li>
              <li
                *ngIf="
                  (!authService.state && router.url.includes('/esg')) ||
                  authService.currentUserValue !== null
                "
              >
                <a
                  name="esg-onboarding"
                  id="esg-onboarding"
                  (click)="restartOnboarding()"
                >
                  {{ "header.tools.onboarding" | translate }}
                </a>
              </li>
              <!-- <li>
                <a target="_blank"
                  href="https://github.com/LINCnil/pia/blob/master/CONTRIBUTING.md#exprimer-un-besoin-ou-probl%C3%A8me">
                  {{ "header.help.issue_reporting" | translate }}
                </a>
              </li> -->
            </ul>
          </li>
        </ng-container>
        <!-- esg-navigationBlock-languages -->
        <li
          class="esg-drop-li esg-navigationBlock-withsub esg-navigationBlock-dropdown "
        >
          <a href="javascript:;">
            <i class="fa fa-globe" aria-hidden="true"></i>
          </a>
          <ul style="margin: 12px -3px 0px 0px !important;width: 200px;">
            <!-- <li class="officialTranslation">
              {{ "header.languages.official_translation" | translate }}
            </li> -->
            <li
              [ngClass]="{
                is_active: languagesService.selectedLanguage === 'fr'
              }"
            >
              <a
                href="javascript:;"
                (click)="languagesService.updateCurrentLanguage('fr')"
              >
                {{ "header.languages.fr" | translate }}
              </a>
            </li>
            <!-- <li class="cnilTranslations">
              {{ "header.languages.cnil_translations" | translate }}
            </li> -->
            <li
              class="englishTranslation"
              [ngClass]="{
                is_active: languagesService.selectedLanguage === 'en'
              }"
            >
              <a
                href="javascript:;"
                (click)="languagesService.updateCurrentLanguage('en')"
              >
                <!-- <a href="javascript:;"> -->
                {{ "header.languages.en" | translate }}
              </a>
            </li>
            <!-- <li class="dpaTranslations">
              {{ "header.languages.dpa_translations" | translate }}
            </li> -->
            <!-- <li
              [ngClass]="{
                is_active: languagesService.selectedLanguage === 'de'
              }"
            >
              <a
                href="javascript:;"
                (click)="languagesService.updateCurrentLanguage('de')"
              >
                {{ "header.languages.de" | translate }}
              </a>
            </li>
             <li
              [ngClass]="{
                is_active: languagesService.selectedLanguage === 'it'
              }"
            >
              <a
                href="javascript:;"
                (click)="languagesService.updateCurrentLanguage('it')"
              >
                {{ "header.languages.it" | translate }}
              </a>
            </li>
            <li
              [ngClass]="{
                is_active: languagesService.selectedLanguage === 'hu'
              }"
            >
              <a
                href="javascript:;"
                (click)="languagesService.updateCurrentLanguage('hu')"
              >
                {{ "header.languages.hu" | translate }}
              </a>
            </li>
            <li
              [ngClass]="{
                is_active: languagesService.selectedLanguage === 'no'
              }"
            >
              <a
                href="javascript:;"
                (click)="languagesService.updateCurrentLanguage('no')"
              >
                {{ "header.languages.no" | translate }}
              </a>
            </li>
            <li
              [ngClass]="{
                is_active: languagesService.selectedLanguage === 'pl'
              }"
            >
              <a
                href="javascript:;"
                (click)="languagesService.updateCurrentLanguage('pl')"
              >
                {{ "header.languages.pl" | translate }}
              </a>
            </li>
            <li
              [ngClass]="{
                is_active: languagesService.selectedLanguage === 'fi'
              }"
            >
              <a
                href="javascript:;"
                (click)="languagesService.updateCurrentLanguage('fi')"
              >
                {{ "header.languages.fi" | translate }}
              </a>
            </li>
            <li
              [ngClass]="{
                is_active: languagesService.selectedLanguage === 'lv'
              }"
            >
              <a
                href="javascript:;"
                (click)="languagesService.updateCurrentLanguage('lv')"
              >
                {{ "header.languages.lv" | translate }}
              </a>
            </li>
            <li class="communityTranslations">
              {{ "header.languages.community_translations" | translate }}
            </li>
            <li
              [ngClass]="{
                is_active: languagesService.selectedLanguage === 'cz'
              }"
            >
              <a
                href="javascript:;"
                (click)="languagesService.updateCurrentLanguage('cz')"
              >
                {{ "header.languages.cz" | translate }}
              </a>
            </li>
            <li
              [ngClass]="{
                is_active: languagesService.selectedLanguage === 'dk'
              }"
            >
              <a
                href="javascript:;"
                (click)="languagesService.updateCurrentLanguage('dk')"
              >
                {{ "header.languages.dk" | translate }}
              </a>
            </li>
            <li
              [ngClass]="{
                is_active: languagesService.selectedLanguage === 'et'
              }"
            >
              <a
                href="javascript:;"
                (click)="languagesService.updateCurrentLanguage('et')"
              >
                {{ "header.languages.et" | translate }}
              </a>
            </li>
            <li
              [ngClass]="{
                is_active: languagesService.selectedLanguage === 'es'
              }"
            >
              <a
                href="javascript:;"
                (click)="languagesService.updateCurrentLanguage('es')"
              >
                {{ "header.languages.es" | translate }}
              </a>
            </li>
            <li
              [ngClass]="{
                is_active: languagesService.selectedLanguage === 'el'
              }"
            >
              <a
                href="javascript:;"
                (click)="languagesService.updateCurrentLanguage('el')"
              >
                {{ "header.languages.el" | translate }}
              </a>
            </li>
            <li
              [ngClass]="{
                is_active: languagesService.selectedLanguage === 'hr'
              }"
            >
              <a
                href="javascript:;"
                (click)="languagesService.updateCurrentLanguage('hr')"
              >
                {{ "header.languages.hr" | translate }}
              </a>
            </li>
            <li
              [ngClass]="{
                is_active: languagesService.selectedLanguage === 'lt'
              }"
            >
              <a
                href="javascript:;"
                (click)="languagesService.updateCurrentLanguage('lt')"
              >
                {{ "header.languages.lt" | translate }}
              </a>
            </li> -->
            <li
              [ngClass]="{
                is_active: languagesService.selectedLanguage === 'nl'
              }"
            >
              <!-- <a href="javascript:;"> -->
              <a
                href="javascript:;"
                (click)="languagesService.updateCurrentLanguage('nl')"
              >
                {{ "header.languages.nl" | translate }}
              </a>
            </li>
            <!-- <li
              [ngClass]="{
                is_active: languagesService.selectedLanguage === 'pt'
              }"
            >
              <a
                href="javascript:;"
                (click)="languagesService.updateCurrentLanguage('pt')"
              >
                {{ "header.languages.pt" | translate }}
              </a>
            </li>
            <li
              [ngClass]="{
                is_active: languagesService.selectedLanguage === 'ro'
              }"
            >
              <a
                href="javascript:;"
                (click)="languagesService.updateCurrentLanguage('ro')"
              >
                {{ "header.languages.ro" | translate }}
              </a>
            </li>
            <li
              [ngClass]="{
                is_active: languagesService.selectedLanguage === 'sl'
              }"
            >
              <a
                href="javascript:;"
                (click)="languagesService.updateCurrentLanguage('sl')"
              >
                {{ "header.languages.sl" | translate }}
              </a>
            </li>
            <li
              [ngClass]="{
                is_active: languagesService.selectedLanguage === 'sv'
              }"
            >
              <a
                href="javascript:;"
                (click)="languagesService.updateCurrentLanguage('sv')"
              >
                {{ "header.languages.sv" | translate }}
              </a>
            </li>
            <li
              [ngClass]="{
                is_active: languagesService.selectedLanguage === 'lv'
              }"
            >
              <a
                href="javascript:;"
                (click)="languagesService.updateCurrentLanguage('lv')"
              >
                {{ "header.languages.lv" | translate }}
              </a>
            </li>
            <li
              [ngClass]="{
                is_active: languagesService.selectedLanguage === 'bg'
              }"
            >
              <a
                href="javascript:;"
                (click)="languagesService.updateCurrentLanguage('bg')"
              >
                {{ "header.languages.bg" | translate }}
              </a>
            </li> -->
          </ul>
        </li>
      </ul>
    </div>

    <!-- </div> -->
  </div>
  <div
    *ngIf="
      !authService.state && !authService.currentUserValue && router.url !== '/'
    "
  >
    <span
      [ngClass]="{
        'text-initial-enVersion': languagesService.selectedLanguage === 'en',
        'text-initial-frVersion': languagesService.selectedLanguage === 'fr',
        'text-initial-nlVersion': languagesService.selectedLanguage === 'nl'
      }"
    >
      {{ "header.initial_text" | translate }}
    </span>
    <img
      class="vr_initial-position"
      src="assets/images/vr_initial.svg"
      alt="Image Initial"
    />
  </div>

  <app-reservation-dialog
    *ngIf="
      showModalReservation &&
      !authService.state &&
      !authService.currentUserValue &&
      router.url !== '/'
    "
    (clickOnClose)="showModalReservation = false"
  >
    <ng-container>
      <div class="reservation-modal">
        <i
          style="font-size: 55px;
        color: #FF9A3F;"
          class="fa-solid fa-bell"
        ></i>
        <span
          >{{ "header.reserve_modal.text1" | translate
          }}<span style="font-weight: bold;">
            {{ "header.reserve_modal.text2" | translate }}</span
          ></span
        >
        <a
          href="https://calendly.com/bks-ok-r/30min?back=1"
          target="_blank"
          class="btn btn-reservation-esg-outline"
        >
          <div style="padding: 7px;display:flex;gap:6px">
            <img src="assets/images/calendly-logo.svg" alt="Logo calendly" />{{
              "header.reserve_modal.btn" | translate
            }}
          </div>
        </a>
      </div>
    </ng-container>
  </app-reservation-dialog>
</header>
