<div class="esg-knowledgeBaseBlock-item" [attr.data-slug]="itemKb.slug">
  <div>
    <div class="esg-knowledgeBaseBlock-item-accordion">
      <button
        class="btn"
        type="button"
        (click)="displayItem()"
        title="{{ 'misc.display' | translate }}"
      >
        <span class="esg-icons esg-icon-accordion-down"></span>
      </button>
    </div>
    <div class="esg-knowledgeBaseBlock-item-definition">
      <p (click)="displayItem()">{{ itemKb.category | translate }}</p>
      <button
        *ngIf="
          itemKb.filters.startsWith('measure.') &&
          itemKb.filters !== 'measure.definition' &&
          _globalEvaluationService.answerEditionEnabled &&
          (editMode.includes('author') || editMode === 'local')
        "
        class="btn"
        type="button"
        (click)="addNewMeasure()"
        [ngClass]="{
          hide: this._knowledgeBaseService.toHide.includes(titleKb)
        }"
        title="{{ 'knowledge_base.placeholder_add_measure' | translate }}"
      >
        <span class="esg-icons esg-icon-add"></span>
        <span>{{ "knowledge_base.add_measure" | translate }}</span>
      </button>
      <div style="display:none;">
        <span class="esg-icons esg-icon-hand"></span>
        <p>{{ "knowledge_base.add_measure_hint" | translate }}</p>
      </div>
    </div>
  </div>
  <div class="esg-knowledgeBaseBlock-item-title">
    <p (click)="displayItem()">{{ itemKb.name | translate }}</p>
  </div>
  <div
    class="esg-knowledgeBaseBlock-item-content hide"
    [innerHTML]="itemKb.description | translate"
  ></div>
</div>
