<section class="container esg-mainContainerBlock" *ngIf="structure">
  <div class="esg-breadcrumb hide-for-print">
    <a [routerLink]="['/entries', 'structure']">{{
      "header.structures" | translate
    }}</a
    ><strong> > {{ structure.name }}</strong>
  </div>
  <div class="row">
    <!-- Left sidebar (sections) -->
    <div class="small-12 medium-3 columns">
      <aside class="esg-leftSidebarBlock">
        <app-sections
          [structure]="structure"
          [section]="section"
          [item]="item"
        ></app-sections>
      </aside>
    </div>

    <!-- Main entry content -->
    <div class="small-12 medium-6 columns">
      <app-content
        [structure]="structure"
        [questions]="questions"
        [section]="section"
        [item]="item"
        [data]="data"
      ></app-content>
    </div>

    <!-- Right sidebar (knowledge base) -->
    <div class="small-12 medium-3 columns">
      <div class="esg-rightSidebarBlock">
        <app-knowledge-base
          [structure]="structure"
          [item]="item"
        ></app-knowledge-base>
      </div>
    </div>
  </div>
</section>
<app-dialog></app-dialog>
