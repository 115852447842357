<td class="esg-listsBlock-item">
  <div class="esg-listsBlock-item-actions">
    <a
      *ngIf="structure.is_example; else pencilBlock"
      [routerLink]="['/structures', structure.id, 'section', 1, 'item', 1]"
      class="btn esg-tooltip"
    >
      <i class="fa fa-eye" aria-hidden="true"></i>
      <span
        title="{{ 'homepage.cards.item.consult_esg' | translate }}"
        class="esg-tooltip-text"
        >{{ "homepage.cards.item.consult_esg" | translate }}</span
      >
    </a>
    <ng-template #pencilBlock>
      <a
        [routerLink]="['/structures', structure.id, 'section', 1, 'item', 1]"
        class="btn esg-tooltip"
      >
        <i class="fa fa-pencil" aria-hidden="true"></i>
        <span
          title="{{ 'homepage.lists.item.tools.edit' | translate }}"
          class="esg-tooltip-text"
          >{{ "homepage.lists.item.tools.edit" | translate }}</span
        >
      </a>
    </ng-template>
    <a
      href="javascript:;"
      (click)="export(structure.id)"
      class="btn esg-tooltip"
    >
      <i class="fa fa-download" aria-hidden="true"></i>
      <span
        title="{{ 'homepage.lists.item.tools.export' | translate }}"
        class="esg-tooltip-text"
        >{{ "homepage.lists.item.tools.export" | translate }}</span
      >
    </a>
    <a
      href="javascript:;"
      (click)="duplicate(structure.id)"
      class="btn esg-tooltip"
    >
      <i class="fa-solid fa-copy" aria-hidden="true"></i>
      <span
        title="{{ 'homepage.lists.item.tools.duplicate' | translate }}"
        class="esg-tooltip-text"
        >{{ "homepage.lists.item.tools.duplicate" | translate }}</span
      >
    </a>
    <a
      href="javascript:;"
      (click)="remove(structure.id)"
      class="btn esg-tooltip"
      *ngIf="!structure.is_example"
    >
      <i class="fa-solid fa-trash" aria-hidden="true"></i>
      <span
        title="{{ 'homepage.lists.item.tools.remove' | translate }}"
        class="esg-tooltip-text"
        >{{ "homepage.lists.item.tools.remove" | translate }}</span
      >
    </a>
  </div>
</td>
<td class="esg-listsBlock-item">
  <div
    *ngIf="!structure.is_example; else readOnlyName"
    contenteditable
    (focusout)="onFocusOut('name', $event)"
  >
    {{ structure.name }}
  </div>
  <ng-template #readOnlyName>
    <div>{{ structure.name }}</div>
  </ng-template>
</td>
<td class="esg-listsBlock-item">
  <time
    *ngIf="
      structure.updated_at && structure.updated_at.toString() !== 'Invalid Date'
    "
    >{{
      structure.updated_at
        | date: "shortDate":"":languagesService.selectedLanguage
    }}</time
  >
</td>
<td class="esg-listsBlock-item">
  <div
    *ngIf="!structure.is_example; else readOnlySectorName"
    contenteditable
    (focusout)="onFocusOut('sector_name', $event)"
  >
    {{ structure.sector_name }}
  </div>
  <ng-template #readOnlySectorName>
    <div>{{ structure.sector_name }}</div>
  </ng-template>
</td>
<td class="esg-listsBlock-item">
  <time>{{
    structure.created_at
      | date: "shortDate":"":languagesService.selectedLanguage
  }}</time>
</td>
