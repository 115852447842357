<form style="border-radius: 10px;"  (submit)="ngOnSubmit()" [formGroup]="logIn">
  <!-- <fieldset>
    <div>
      <legend>{{ "authentication.sign_in" | translate }}</legend>
      <p class="msg is-valid" *ngIf="fromSignUp">
        {{ "authentication.saved_password" | translate }}
      </p>
      <p class="msg" *ngIf="msgFromBack">
        {{
          msgFromBack != "incompatibility"
            ? "authentication.errors.sign_in." + msgFromBack
            : ("authentication.errors." + msgFromBack | translate)
        }}
      </p>
      <div [class.is-not-empty]="f.login.value.length > 0">
        <input
          type="text"
          placeholder="{{ 'authentication.login' | translate }}"
          id="esg-account-login"
          formControlName="login"
        />
        <label style="color: #775ac7;" for="esg-account-login">
          {{ "authentication.login" | translate }}
        </label>
      </div>

      <div [class.is-not-empty]="f.password.value.length > 0">
        <input
          type="password"
          placeholder="{{ 'authentication.password' | translate }}"
          id="esg-account-password"
          formControlName="password"
        />
        <label style="color: #775ac7 !important;" for="esg-account-password">
          {{ "authentication.password" | translate }}
        </label>
      </div>

      <a style="color: #775ac7;" href="#" (click)="onForget()">{{
        "authentication.forgotten_password" | translate
      }}</a>
    </div>
    <div class="validForm">
      <button style="color: #775ac7;" (click)="onCanceled()" type="button">
        {{ "authentication.activate_account" | translate }}
      </button>
      <button
        [disabled]="logIn.invalid || loading"
        type="submit"
        class="btn btn-green"
      >
        <ng-container *ngIf="loading; else loadingElse">
          ...
        </ng-container>
        <ng-template #loadingElse>
          {{ "authentication.sign_in_button" | translate }}
        </ng-template>
      </button>
    </div>
  </fieldset> -->

  <div style="display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 100vh;">
    <span style="font-size: 24px; font-weight:600;text-align:center;margin-bottom:7px">{{ "authentication.sign_in" |
      translate }}
    </span>
    <!-- <span style="font-size: 13px; font-weight:400;text-align:center;color:#71717A">Enter your email below to login to
      your account
    </span> -->

    <div [class.is-not-empty]="f.login.value.length > 0" style="display: flex;
    flex-direction: column;
    gap: 7px;
    width: 50%;
    margin-top: 24px;">
      <label for="esg-account-login" style="font-weight: 600;">{{ "authentication.login" | translate }}</label>
      <input type="text" placeholder="{{ 'authentication.login' | translate }}" id="esg-account-login"
        formControlName="login" style="padding:11.25px 13px 11.25px 13px;outline-width: 0;border:1px solid #E4E4E7;border-radius: 6px;"
        type="text">
    </div>

    <div [class.is-not-empty]="f.password.value.length > 0" style="display: flex;
    flex-direction: column;
    gap: 7px;
    width: 50%;
    margin-top: 24px;">
    <div style="display: flex; justify-content:space-between; align-items:center">
      <label for="esg-account-password" style="font-weight: 600;">{{ 'authentication.password' | translate }}</label>
      <a href="#" (click)="onForget()">{{
        "authentication.forgotten_password" | translate
      }}</a>
    </div>
      <input style="padding:11.25px 13px 11.25px 13px;outline-width: 0;border:1px solid #E4E4E7;border-radius: 6px;" type="text"
        type="password" placeholder="{{ 'authentication.password' | translate }}" id="esg-account-password"
        formControlName="password">
    </div>




    <div style="display: flex;
    flex-direction: column;
    gap: 7px;
    width: 50%;
    margin-top: 24px;">

    <button
        [disabled]="logIn.invalid || loading"
        type="submit"
        class="button-auth-primary"
      >
        <ng-container *ngIf="loading; else loadingElse">
          ...
        </ng-container>
        <ng-template #loadingElse>
          {{ "authentication.sign_in_button" | translate }}
        </ng-template>
      </button>

      <div style="position: relative; margin-top:20px;margin-bottom:20px">
        <div style="position: absolute; top: 0; right: 0; bottom: 0; left: 0; display: flex; align-items: center;">
            <span style="border-top: 1px solid #E4E4E7; width: 100%;"></span>
        </div>
        <div style="position: relative; display: flex; justify-content: center; font-size: 0.75rem; text-transform: uppercase;">
            <span style="font-size:12px;font-weight:400;background-color: #FFF; padding-left: 0.5rem; padding-right: 0.5rem; color: #71717A;">{{ "authentication.first_cnx" | translate }}</span>
        </div>
    </div>

      <button class="button-auth-outline" (click)="onCanceled()" type="button">
        <i style="margin-right: 5px;" class="fa-solid fa-check"></i> {{ "authentication.activate_account" | translate }}
      </button>
    </div>
  </div>
</form>
