<div
  class="container esg-mainContainerBlock"
  [ngClass]="{
    'esg-mainContainerBlock-structures':
      appDataService.entrieMode === 'structure',
    'esg-mainContainerBlock-archives': appDataService.entrieMode === 'archive',
    'esg-mainContainerBlock-knowledges':
      appDataService.entrieMode === 'knowledgeBase'
  }"
>
  <div class="row">
    <div class="small-12 medium-12 columns">
      <div class="esg-breadcrumb">
        <strong>
          <ng-container
            *ngIf="type_entries === 'pia' || type_entries === 'archive'"
            >{{ "header.homepage_link" | translate }} >
          </ng-container>
          <ng-container *ngIf="type_entries === 'pia'">{{
            "header.current_esg" | translate
          }}</ng-container>
          <ng-container *ngIf="type_entries === 'structure'">{{
            "header.structures" | translate
          }}</ng-container>
          <ng-container *ngIf="type_entries === 'archive'">{{
            "header.archives" | translate
          }}</ng-container>
          <ng-container *ngIf="type_entries === 'knowledgeBase'">{{
            "header.knowledge_base_link" | translate
          }}</ng-container>
        </strong>
      </div>
      <div class="esg-filtersBlock">
        <div class="esg-filtersBlock-left">
          <!-- <span>{{viewStyle.view}}</span>
          <span>{{showPiaForm}}</span> -->

          <!-- <button class="esg-filtersBlock-switch btn" type="button" *ngIf="viewStyle.view === 'list'"
            (click)="viewOnCard()">
            <i class="fa fa-list" aria-hidden="true"></i>
            <div>{{ "homepage.filters.card" | translate }}</div>
          </button> -->
          <!-- <button class="esg-filtersBlock-switch btn" type="button" *ngIf="viewStyle.view === 'card'"
            (click)="viewOnList()">
            <i class="fa fa-list" aria-hidden="true"></i>
            <div>{{ "homepage.filters.list" | translate }}</div>
          </button> -->
          <ng-container
            *ngIf="
              type_entries === 'pia' ||
              type_entries === 'structure' ||
              type_entries === 'knowledgeBase'
            "
          >
            <!-- *ngIf="
                !authService.state || (showPiaForm && viewStyle.view === 'list')
              " -->
            <div
              *ngIf="
                !authService.state ||
                (showPiaForm &&
                  (viewStyle.view === 'list' || viewStyle.view === 'card'))
              "
              class="esg-filtersBlock-buttons"
            >
              <button
                type="button"
                class="btn"
                [ngClass]="
                  appDataService.entrieMode === 'knowledgeBase'
                    ? 'btn-red'
                    : 'btn-green'
                "
                (click)="showModal = true"
              >
                <i class="fa fa-folder-open" aria-hidden="true"></i>
                <ng-container *ngIf="type_entries === 'pia'">{{
                  "homepage.cards.new_esg" | translate
                }}</ng-container>
                <ng-container *ngIf="type_entries === 'structure'">{{
                  "homepage.cards.new_structure" | translate
                }}</ng-container>
                <ng-container *ngIf="type_entries === 'knowledgeBase'">{{
                  "homepage.cards.knowledges.new" | translate
                }}</ng-container>
              </button>
              <button
                type="button"
                class="btn"
                [ngClass]="
                  appDataService.entrieMode === 'knowledgeBase'
                    ? 'btn-red'
                    : 'btn-green'
                "
                (click)="import()"
              >
                <i class="fa fa-upload" aria-hidden="true"></i>
                <ng-container *ngIf="type_entries === 'pia'">{{
                  "homepage.cards.import_esg" | translate
                }}</ng-container>
                <ng-container *ngIf="type_entries === 'structure'">{{
                  "homepage.cards.import_structure" | translate
                }}</ng-container>
                <ng-container *ngIf="type_entries === 'knowledgeBase'">{{
                  "homepage.cards.knowledges.import" | translate
                }}</ng-container>
              </button>
            </div>
            <div
              class="esg-filtersBlock-hint"
              *ngIf="
                viewStyle.view === 'list' && entries && entries.length === 0
              "
            >
              <div>
                <span class="esg-icons esg-icon-rocket-small"></span>
                <p
                  [innerHTML]="
                    'homepage.filters.list_rocket_content' | translate
                  "
                ></p>
              </div>
            </div>
            <div class="hide">
              <form enctype="multipart/form-data" [formGroup]="importForm">
                <input
                  type="file"
                  formControlName="import_file"
                  id="import_file"
                  (change)="import($event)"
                  class="hide"
                />
              </form>
              <a href="" id="esg-exportBlock"></a>
            </div>
          </ng-container>
        </div>

        <div class="esg-filtersBlock-right">
          <button
            class="esg-filtersBlock-switch btn esg-filtersBlock-filters"
            type="button"
            *ngIf="viewStyle.view === 'list'"
            (click)="viewOnCard()"
          >
            <i class="fa fa-list" aria-hidden="true"></i>
            <div>{{ "homepage.filters.card" | translate }}</div>
          </button>
          <button
            class="esg-filtersBlock-switch esg-filtersBlock-filters btn"
            type="button"
            *ngIf="viewStyle.view === 'card'"
            (click)="viewOnList()"
          >
            <i class="fa fa-list" aria-hidden="true"></i>
            <div>{{ "homepage.filters.list" | translate }}</div>
          </button>

          <div
            class="esg-filtersBlock-filters"
            *ngIf="viewStyle.view === 'card'"
          >
            <button class="esg-filtersBlock-filters-btn btn" type="button">
              <i class="fa fa-filter"></i>
              {{ "homepage.filters.sort" | translate }}
              <span
                class="esg-icons"
                [ngClass]="
                  appDataService.entrieMode === 'knowledgeBase'
                    ? 'esg-icon-scroll-red'
                    : 'esg-icon-scroll-green'
                "
              ></span>
            </button>
            <div class="esg-filtersBlock-filters-list">
              <span>{{ "homepage.filters.sort_by" | translate }}</span>
              <ul>
                <ng-container [ngSwitch]="type_entries">
                  <ng-container *ngSwitchCase="'structure'">
                    <li [ngClass]="{ active: sortValue === 'name' }">
                      <a (click)="sortBy('name')">{{
                        "homepage.filters.name" | translate
                      }}</a>
                    </li>
                    <li [ngClass]="{ active: sortValue === 'sector_name' }">
                      <a (click)="sortBy('sector_name')">{{
                        "homepage.filters.sector_name" | translate
                      }}</a>
                    </li>
                    <li [ngClass]="{ active: sortValue === 'created_at' }">
                      <a (click)="sortBy('created_at')">{{
                        "homepage.filters.date" | translate
                      }}</a>
                    </li>
                  </ng-container>
                  <ng-container *ngSwitchCase="'knowledgeBase'">
                    <li [ngClass]="{ active: sortValue === 'name' }">
                      <a (click)="sortBy('name')">{{
                        "homepage.filters.name" | translate
                      }}</a>
                    </li>
                    <li [ngClass]="{ active: sortValue === 'author' }">
                      <a (click)="sortBy('author')">{{
                        "homepage.cards.knowledges.placeholder_author"
                          | translate
                      }}</a>
                    </li>
                    <li [ngClass]="{ active: sortValue === 'contributors' }">
                      <a (click)="sortBy('contributors')">{{
                        "homepage.cards.knowledges.placeholder_contributors"
                          | translate
                      }}</a>
                    </li>
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                    <li [ngClass]="{ active: sortValue === 'name' }">
                      <a (click)="sortBy('name')">{{
                        "homepage.filters.name" | translate
                      }}</a>
                    </li>
                    <li [ngClass]="{ active: sortValue === 'category' }">
                      <a (click)="sortBy('category')">{{
                        "homepage.filters.category" | translate
                      }}</a>
                    </li>
                    <li [ngClass]="{ active: sortValue === 'created_at' }">
                      <a (click)="sortBy('created_at')">{{
                        "homepage.filters.date" | translate
                      }}</a>
                    </li>
                    <li [ngClass]="{ active: sortValue === 'status' }">
                      <a (click)="sortBy('status')">{{
                        "homepage.filters.status" | translate
                      }}</a>
                    </li>
                    <li [ngClass]="{ active: sortValue === 'author_name' }">
                      <a (click)="sortBy('author_name')">{{
                        "homepage.filters.author" | translate
                      }}</a>
                    </li>
                    <li [ngClass]="{ active: sortValue === 'evaluator_name' }">
                      <a (click)="sortBy('evaluator_name')">{{
                        "homepage.filters.assessor" | translate
                      }}</a>
                    </li>
                    <li [ngClass]="{ active: sortValue === 'validator_name' }">
                      <a (click)="sortBy('validator_name')">{{
                        "homepage.filters.validator" | translate
                      }}</a>
                    </li>
                  </ng-container>
                </ng-container>
              </ul>
            </div>
          </div>

          <div class="esg-searchInput">
            <input
              type="text"
              class="btn btn-search"
              [ngClass]="{ typed: searchText && searchText.length > 0 }"
              [(ngModel)]="searchText"
              placeholder="{{ 'homepage.search_filter' | translate }}"
              name="search"
              autocomplete="off"
            />
            <i class="search-icon">⚲</i>
            <i
              class="search-close"
              (click)="onCleanSearch()"
              *ngIf="searchText && searchText.length > 0"
              >×</i
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <!-- "Card" mode -->
    <div
      *ngIf="viewStyle.view === 'card'; else viewList"
      class="small-12 medium-12 columns esg-list"
    >
      <!-- New PIA -->
      <!-- <div
        *ngIf="
          showPiaForm &&
          (type_entries === 'pia' ||
            type_entries === 'structure' ||
            type_entries === 'knowledgeBase')
        "
        class="esg-cardsBlock esg-newBlock"
        id="cardsSwitch"
      >
        <div class="esg-newBlock-item front" id="esg-new-card">
          <div class="esg-newBlock-item-new">
            <div class="esg-newBlock-item-new-title">
              <ng-container *ngIf="type_entries === 'pia'">{{
                "homepage.cards.new_esg" | translate
              }}</ng-container>
              <ng-container *ngIf="type_entries === 'structure'">{{
                "homepage.cards.new_structure" | translate
              }}</ng-container>
              <ng-container *ngIf="type_entries === 'knowledgeBase'">{{
                "homepage.cards.knowledges.new" | translate
              }}</ng-container>
            </div>
            <div class="esg-newBlock-item-new-btn">
              <i class="fa fa-folder-open" aria-hidden="true"></i>
              <button
                class="btn"
                (click)="open()"
                type="button"
                [attr.title]="
                  'homepage.cards.placeholder_new_' + type_entries | translate
                "
              >
                <span
                  class="esg-icons"
                  [ngClass]="
                    appDataService.entrieMode === 'knowledgeBase'
                      ? 'esg-icon-esg-plus-red'
                      : 'esg-icon-esg-plus'
                  "
                ></span>
              </button>
            </div>
          </div>
          <span>{{ "homepage.cards.or" | translate }}</span>
          <div class="esg-newBlock-item-import">
            <div class="esg-newBlock-item-import-title">
              <ng-container *ngIf="type_entries === 'pia'">{{
                "homepage.cards.import_esg" | translate
              }}</ng-container>
              <ng-container *ngIf="type_entries === 'structure'">{{
                "homepage.cards.import_structure" | translate
              }}</ng-container>
              <ng-container *ngIf="type_entries === 'knowledgeBase'">{{
                "homepage.cards.knowledges.import" | translate
              }}</ng-container>
            </div>
            <div class="esg-newBlock-item-import-btn">
              <i class="fa fa-upload" aria-hidden="true"></i>
              <button
                class="btn"
                (click)="import()"
                type="button"
                [attr.title]="
                  'homepage.cards.placeholder_import_' + type_entries
                    | translate
                "
              >
                <span
                  class="esg-icons"
                  [ngClass]="
                    appDataService.entrieMode === 'knowledgeBase'
                      ? 'esg-icon-esg-plus-red'
                      : 'esg-icon-esg-plus'
                  "
                ></span>
              </button>
            </div>
          </div>
        </div>
        <div class="esg-cardsBlock esg-editBlock back">
          <div class="esg-cardsBlock-item esg-editBlock-item">
            <button
              class="btn"
              (click)="reverse()"
              type="button"
              [attr.title]="'homepage.cards.title_close_creation' | translate"
            >
              <span class="esg-icons esg-icon-close"></span>
            </button>

            <ng-container [ngSwitch]="type_entries">
              <ng-container *ngSwitchCase="'pia'">
                <app-new-pia
                  [users]="users"
                  (newUserNeeded)="onNewUserNeeded($event)"
                  (submitted)="onFormsubmitted($event, 'pia')"
                >
                </app-new-pia>
              </ng-container>
              <ng-container *ngSwitchCase="'structure'">
                <app-new-structure
                  (submitted)="onFormsubmitted($event, 'structure')"
                >
                </app-new-structure>
              </ng-container>
              <ng-container *ngSwitchCase="'knowledgeBase'">
                <app-new-knowledgebase
                  (submitted)="onFormsubmitted($event, 'knowledgeBase')"
                >
                </app-new-knowledgebase>
              </ng-container>
              <ng-container *ngSwitchDefault> </ng-container>
            </ng-container>
          </div>
        </div>
      </div> -->
      <!-- END New PIA -->
      <ng-container *ngIf="!loading; else loadingEntries">
        <!-- PIA, ARCHIVE Cards switch -->
        <ng-container [ngSwitch]="type_entries">
          <ng-container *ngSwitchCase="'pia'">
            <div
              *ngIf="entries.length <= 0 && !loading"
              style="display:table-column; margin: auto;"
            >
              <img
                style="margin-bottom: 10px;"
                src="assets/images/image-empty.svg"
                alt="Empty ESG"
              />
              <div class="no-pia">
                {{ "homepage.no_entries.esg" | translate }}
              </div>
            </div>
            <app-pia-card
              *ngFor="
                let pia of entries | filterForUser: searchText;
                let i = index
              "
              [users]="users"
              (conflictDetected)="conflictDialog($event)"
              (newUserNeeded)="onNewUserNeeded($event)"
              (changed)="updateEntrie($event)"
              (duplicated)="refreshContent()"
              (archived)="refreshContent()"
              [pia]="pia"
              [previousPia]="i > 0 ? entries[i - 1] : null"
            >
            </app-pia-card>
          </ng-container>
          <ng-container *ngSwitchCase="'archive'">
            <div *ngIf="entries.length <= 0" class="no-pia">
              {{ "homepage.no_entries.archive" | translate }}
            </div>
            <app-archive-card
              *ngFor="
                let archivedPia of entries | filterForUser: searchText;
                let i = index
              "
              [archivedPia]="archivedPia"
              (deleted)="refreshContent()"
              [previousArchivedPia]="i > 0 ? entries[i - 1] : null"
            >
            </app-archive-card>
          </ng-container>
          <ng-container *ngSwitchCase="'structure'">
            <app-structure-card
              *ngFor="
                let structure of entries | filterForUser: searchText;
                let i = index
              "
              (changed)="updateEntrie($event)"
              (duplicated)="refreshContent()"
              (deleted)="refreshContent()"
              [structure]="structure"
              [previousStructure]="i > 0 ? entries[i - 1] : null"
            >
            </app-structure-card>
          </ng-container>
          <ng-container *ngSwitchCase="'knowledgeBase'">
            <app-knowledgebase-card
              *ngFor="
                let base of entries | filterForUser: searchText;
                let i = index
              "
              (conflictDetected)="conflictDialog($event)"
              (changed)="updateEntrie($event)"
              (duplicated)="refreshContent()"
              (deleted)="refreshContent()"
              [base]="base"
            >
            </app-knowledgebase-card>
          </ng-container>
          <ng-container *ngSwitchDefault> </ng-container>
        </ng-container>
        <!-- ENDING PIA, ARCHIVE Cards switch -->
      </ng-container>
      <ng-template #loadingEntries>
        <div class="entries-loading entries-loading-card">
          <app-loading-overlay
            [visibility]="true"
            [childMode]="true"
          ></app-loading-overlay>
        </div>
      </ng-template>
    </div>

    <!-- "List" mode -->
    <ng-template #viewList>
      <div class="small-12 medium-12 columns">
        <table class="esg-list-table">
          <thead>
            <ng-container [ngSwitch]="type_entries">
              <ng-container *ngSwitchCase="'structure'">
                <tr app-structure-heading (sorting)="sortBy($event)"></tr>
              </ng-container>
              <ng-container *ngSwitchCase="'knowledgeBase'">
                <tr app-knowledgebase-heading (sorting)="sortBy($event)"></tr>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <tr app-pia-heading (sorting)="sortBy($event)"></tr>
              </ng-container>
            </ng-container>
          </thead>
          <ng-container *ngIf="!loading; else loadingEntries">
            <tbody>
              <ng-container [ngSwitch]="type_entries">
                <ng-container *ngSwitchCase="'pia'">
                  <tr
                    app-pia-line
                    class="app-list-item"
                    *ngFor="
                      let pia of entries | filterForUser: searchText;
                      let i = index
                    "
                    (conflictDetected)="conflictDialog($event)"
                    (changed)="updateEntrie($event)"
                    (duplicated)="refreshContent()"
                    (archived)="refreshContent()"
                    [pia]="pia"
                    [users]="users"
                    (newUserNeeded)="onNewUserNeeded($event)"
                  ></tr>
                </ng-container>
                <ng-container *ngSwitchCase="'archive'">
                  <tr
                    app-archive-line
                    class="app-list-item"
                    *ngFor="
                      let archivedPia of entries | filterForUser: searchText;
                      let i = index
                    "
                    [archivedPia]="archivedPia"
                    (deleted)="refreshContent()"
                  ></tr>
                </ng-container>
                <ng-container *ngSwitchCase="'structure'">
                  <tr
                    app-structure-line
                    class="app-list-item"
                    *ngFor="
                      let structure of entries | filterForUser: searchText;
                      let i = index
                    "
                    (changed)="updateEntrie($event)"
                    (duplicated)="refreshContent()"
                    (deleted)="refreshContent()"
                    [structure]="structure"
                  ></tr>
                </ng-container>
                <ng-container *ngSwitchCase="'knowledgeBase'">
                  <tr
                    app-knowledgebase-line
                    class="app-list-item"
                    *ngFor="
                      let base of entries | filterForUser: searchText;
                      let i = index
                    "
                    (conflictDetected)="conflictDialog($event)"
                    (changed)="updateEntrie($event)"
                    (duplicated)="refreshContent()"
                    (deleted)="refreshContent()"
                    [base]="base"
                  ></tr>
                </ng-container>
                <ng-container *ngSwitchDefault> </ng-container>
              </ng-container>
            </tbody>
          </ng-container>
          <ng-template #loadingEntries>
            <tbody>
              <div class="entries-loading entries-loading-line">
                <app-loading-overlay
                  [visibility]="true"
                  [childMode]="true"
                ></app-loading-overlay>
              </div>
            </tbody>
          </ng-template>
        </table>
        <div *ngIf="entries.length <= 0 && !loading" class="entries-missing">
          {{ "homepage.no_entries." + type_entries | translate }}
        </div>
      </div>
    </ng-template>
  </div>
</div>

<app-modal
  *ngIf="showModal"
  [specialClass]="
    'card ' + (type_entries === 'knowledgeBase' ? 'card-knowledge' : '')
  "
  (clickOnClose)="showModal = false"
>
  <ng-container [ngSwitch]="type_entries">
    <ng-container *ngSwitchCase="'pia'">
      <app-new-pia
        [users]="users"
        (newUserNeeded)="onNewUserNeeded($event)"
        (submitted)="onFormsubmitted($event, 'pia'); showModal = false"
      >
      </app-new-pia>
    </ng-container>
    <ng-container *ngSwitchCase="'structure'">
      <app-new-structure
        (submitted)="onFormsubmitted($event, 'structure'); showModal = false"
      >
      </app-new-structure>
    </ng-container>
    <ng-container *ngSwitchCase="'knowledgeBase'">
      <app-new-knowledgebase
        (submitted)="
          onFormsubmitted($event, 'knowledgeBase'); showModal = false
        "
      >
      </app-new-knowledgebase>
    </ng-container>
    <ng-container *ngSwitchDefault> </ng-container>
  </ng-container>
</app-modal>

<app-dialog></app-dialog>

<app-modal
  *ngIf="showNewUserForm"
  specialClass="newUserForm"
  (clickOnClose)="onCancelUser()"
>
  <app-new-user
    [forceUserType]="true"
    [user]="userBehavior.value ? userBehavior.value : null"
    (userAdded)="onUserAdded($event)"
    (canceled)="onCancelUser()"
  ></app-new-user>
</app-modal>
