<div class="esg-cardsBlock pia" [attr.data-id]="structure.id">
  <div class="esg-cardsBlock-item esg-doingBlock-item">
    <div class="esg-cardsBlock-toolbar">
      <div class="esg-cardsBlock-toolbar-export">
        <a
          href="javascript:;"
          (click)="export(structure.id)"
          class="btn esg-tooltip"
        >
          <i class="fa fa-download" aria-hidden="true"></i>
          <span
            title="{{ 'homepage.cards.item.tools.export' | translate }}"
            class="esg-tooltip-text"
            >{{ "homepage.cards.item.tools.export" | translate }}</span
          >
        </a>
        <a
          href="javascript:;"
          (click)="duplicate(structure.id)"
          class="btn esg-tooltip"
        >
          <i class="fa-solid fa-copy" aria-hidden="true"></i>
          <span
            title="{{ 'homepage.cards.item.tools.duplicate' | translate }}"
            class="esg-tooltip-text"
            >{{ "homepage.cards.item.tools.duplicate" | translate }}</span
          >
        </a>
        <a
          *ngIf="!structure.is_example"
          href="javascript:;"
          (click)="remove(structure.id)"
          class="btn esg-tooltip"
        >
          <i class="fa-solid fa-trash" aria-hidden="true"></i>
          <span
            title="{{ 'homepage.cards.item.tools.remove' | translate }}"
            class="esg-tooltip-text"
            >{{ "homepage.cards.item.tools.remove" | translate }}</span
          >
        </a>
      </div>
    </div>

    <form class="esg-cardsBlock-item-form" [formGroup]="structureForm">
      <input type="hidden" formControlName="id" />
      <div (click)="structureNameFocusIn()" style="margin-top:20px;">
        <label for="structure-edit-{{ structure.id }}-name">{{
          "homepage.cards.structure_name" | translate
        }}</label>
        <input
          type="text"
          style="margin-top: 4px;"
          #structureName
          formControlName="name"
          required
          placeholder="{{
            'homepage.cards.placeholder_structure_name' | translate
          }}"
          id="structure-edit-{{ structure.id }}-name"
          (focusout)="structureNameFocusOut()"
        />
      </div>
      <div (click)="structureSectorNameFocusIn()">
        <label for="structure-edit-{{ structure.id }}-sector-name">{{
          "homepage.cards.sector_name" | translate
        }}</label>
        <input
          style="margin-top: 4px;"
          type="text"
          #structureSectorName
          formControlName="sector_name"
          required
          placeholder="{{
            'homepage.cards.placeholder_sector_name' | translate
          }}"
          id="structure-edit-{{ structure.id }}-sector-name"
          (focusout)="structureSectorNameFocusOut()"
        />
      </div>
      <div class="esg-cardsBlock-item-date">
        <div style="margin-bottom: 4px;">
          {{ "homepage.cards.date" | translate }}
        </div>
        <time>{{
          structure.created_at
            | date: "shortDate":"":languagesService.selectedLanguage
        }}</time>
      </div>
      <div class="esg-cardsBlock-item-btn">
        <a
          *ngIf="structure.is_example"
          [routerLink]="['/structures', structure.id, 'section', 1, 'item', 1]"
          class="btn btn-green"
          >{{ "homepage.cards.item.consult_structure" | translate }}</a
        >
        <a
          *ngIf="!structure.is_example"
          [routerLink]="['/structures', structure.id, 'section', 1, 'item', 1]"
          class="btn btn-green"
          >{{ "homepage.cards.item.edit_structure" | translate }}</a
        >
      </div>
    </form>
  </div>
</div>
