<!-- <form
  style="border-radius: 10px;"
  class="esg-authenticationBlock-login"
  [formGroup]="uuidActivation"
  (ngSubmit)="ngOnSubmit()"
>
  <fieldset>
    <div
      class="form-group"
      [class.is-not-empty]="f.uuid.value.length > 0"
      [class.is-invalid]="f.uuid.value.length > 0 && f.uuid.errors"
    >
      <ng-container *ngIf="reset; else noReset">
        <legend>{{ "authentication.account_recovery" | translate }}</legend>
        <p class="is-valid">
          {{ "authentication.recovery_code_message" | translate }}
        </p>
      </ng-container>
      <ng-template #noReset>
        <legend>{{ "authentication.create_account" | translate }}</legend>
      </ng-template>

      <p class="msg" *ngIf="msgFromBack">
        {{
          "authentication.errors." +
            (reset ? "account_recovery" : "create_account") +
            ".uuid." +
            msgFromBack | translate
        }}
      </p>

      <input
        type="text"
        placeholder="{{
          reset
            ? ('authentication.recovery_code' | translate)
            : ('authentication.activation_code' | translate)
        }}"
        id="esg-account-activate-code"
        formControlName="uuid"
      />
      <label style="color: #775ac7"
        >{{
          reset
            ? ("authentication.recovery_code" | translate)
            : ("authentication.activation_code" | translate | translate)
        }}
        <span *ngIf="f.uuid.hasError('pattern')">{{
          "authentication.wrong_activation_code" | translate
        }}</span></label
      >
    </div>
    <div class="validForm">
      <button style="color: #775ac7 !important;" (click)="onCanceled()">
        {{ "authentication.cancel" | translate }}
      </button>
      <button
        [disabled]="uuidActivation.invalid || loading"
        type="submit"
        class="btn btn-green"
      >
        <ng-container *ngIf="loading; else loadingElse">
          ...
        </ng-container>
        <ng-template #loadingElse>
          {{ "authentication.continue" | translate }}
        </ng-template>
      </button>
    </div>
  </fieldset>
</form> -->



<form style="border-radius: 10px;" [formGroup]="uuidActivation" (ngSubmit)="ngOnSubmit()">
  <div [class.is-not-empty]="f.uuid.value.length > 0" [class.is-invalid]="f.uuid.value.length > 0 && f.uuid.errors"
    style="display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: auto;
      height: 100vh;">
    <ng-container *ngIf="reset; else noReset">
      <legend>{{ "authentication.account_recovery" | translate }}</legend>
      <p class="is-valid">
        {{ "authentication.recovery_code_message" | translate }}
      </p>
    </ng-container>

    <ng-template #noReset>
      <span style="font-size: 24px; font-weight:600;text-align:center;margin-bottom:7px">{{
        "authentication.create_account" | translate }}
      </span>
    </ng-template>

    <span class="msg" *ngIf="msgFromBack">
      {{
      "authentication.errors." +
      (reset ? "account_recovery" : "create_account") +
      ".uuid." +
      msgFromBack | translate
      }}
    </span>

    <div style="display: flex;
        flex-direction: column;
        gap: 7px;
        width: 50%;
        margin-top: 24px;">

      <label>{{
        reset
        ? ("authentication.recovery_code" | translate)
        : ("authentication.activation_code" | translate | translate)
        }}
        <span *ngIf="f.uuid.hasError('pattern')">{{
          "authentication.wrong_activation_code" | translate
          }}</span></label>

      <input style="padding:11.25px 13px 11.25px 13px;outline-width: 0;border:1px solid #E4E4E7;border-radius: 6px;"
        type="text" type="text" placeholder="{{
        reset
          ? ('authentication.recovery_code' | translate)
          : ('authentication.activation_code' | translate)
      }}" id="esg-account-activate-code" formControlName="uuid" />
    </div>



    <div style="display: flex;
        flex-direction: column;
        gap: 7px;
        width: 50%;
        margin-top: 24px;">



      <button [disabled]="uuidActivation.invalid || loading" type="submit" class="button-auth-primary">
        <ng-container *ngIf="loading; else loadingElse">
          ...
        </ng-container>
        <ng-template #loadingElse>
          {{ "authentication.continue" | translate }}
        </ng-template>
      </button>


      <button class="button-auth-outline" (click)="onCanceled()">
        {{ "authentication.cancel" | translate }}
      </button>

    </div>
  </div>


</form>
