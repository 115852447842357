<div
  class="esg-modalBlock open"
  [id]="message.data && message.data.modal_id ? message.data.modal_id : null"
  *ngIf="message"
>
  <div class="esg-modalBlock-content">
    <button
      *ngIf="!message.data || (message.data && !message.data.no_cross_button)"
      tabindex="3"
      type="button"
      class="esg-modalBlock-close btn"
      (click)="message.noFn()"
      title="{{ 'modals.close' | translate }}"
    >
      <span class="esg-icons esg-icon-close-big"></span>
    </button>
    <ng-container *ngIf="message.data && message.data.date; else templateElse">
      <p
        [innerHTML]="
          message.text
            | translate
              : {
                  date:
                    message.data.date
                    | date: 'short':'':languagesService.selectedLanguage
                }
        "
      ></p>
    </ng-container>
    <ng-template #templateElse>
      <p [innerHTML]="message.text | translate"></p>
    </ng-template>
    <div
      *ngIf="message.data && message.data.additional_text"
      class="abandonText"
      [innerHTML]="message.data.additional_text | translate"
    ></div>
    <p *ngIf="message.icon">
      <i [ngClass]="message.icon" aria-hidden="true"></i>
    </p>
    <div class="esg-modalBlock-buttons-choice">
      <ng-container *ngIf="message.type != 'others'; else othersBtns">
        <button
          tabindex="2"
          style="margin-right: 30px;"
          class="btn"
          [ngClass]="
            message.data && message.data.btn_no
              ? message.data.btn_no
              : 'btn-red'
          "
          (click)="message.noFn()"
          type="button"
          *ngIf="message.type === 'confirm'"
          [innerHtml]="message.no | translate"
        ></button>
        <button
          tabindex="1"
          class="btn"
          *ngIf="message.yes"
          [ngClass]="
            message.data && message.data.btn_yes
              ? message.data.btn_yes
              : 'btn-green'
          "
          type="button"
          (click)="message.yesFn()"
          [innerHtml]="message.yes | translate"
        ></button>
      </ng-container>
      <ng-template #othersBtns>
        <ng-container *ngFor="let btn of message.othersActionsBtn">
          <button
            tabindex="2"
            style="margin-right: 30px;"
            class="btn"
            (click)="btn.callback()"
            type="button"
            [innerHtml]="btn.label | translate"
          ></button>
        </ng-container>
      </ng-template>
    </div>
  </div>
</div>
